import {
  Component,
  OnInit,
  OnDestroy,
  Injectable,
  ChangeDetectorRef,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  Optional,
  HostListener,
} from "@angular/core";
import _ from "lodash";
import { NavigationStart, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from "rxjs";
// Modals
import { LeyendaModalComponent } from "../../modals/leyenda-modal/leyenda-modal.component";
import { ModalCambioPlantafechaComponent } from "../../modals/modal-cambio-plantafecha/modal-cambio-plantafecha.component";
import { ModalCleanButtonComponent } from "../../modals/modal-clean-button/modal-clean-button.component";
import { ModalCloseSessionProgrammateComponent } from "../../modals/modal-close-session-programmate/modal-close-session-programmate.component";
import { ModalDetailsOrderComponent } from "../../modals/modal-details-order/modal-details-order.component";
import { ModalDetailsTruckComponent } from "../../modals/modal-details-truck/modal-details-truck.component";
import { ModalDetallePedidoCombinadoComponent } from "../../modals/modal-detalle-pedido-combinado/modal-detalle-pedido-combinado.component";
import { ModalLargeOrderInfoComponent } from "../../modals/modal-large-order-info/modal-large-order-info.component";
import { ModalMontarPedidosComponent } from "../../modals/modal-montar-pedidos/modal-montar-pedidos.component";
import { ModalNoProgramedOrdersCommentsComponent } from "../../modals/modal-no-programed-orders-comments/modal-no-programed-orders-comments.component";
import { ModalNuevoPedidoCombinadoComponent } from "../../modals/modal-nuevo-pedido-combinado/modal-nuevo-pedido-combinado.component";
import { ModalShiftSwitchComponent } from "../../modals/modal-shift-switch/modal-shift-switch.component";
import { ModalStatusPropuestaComponent } from "../../modals/modal-status-propuesta/modal-status-propuesta.component";
import { ModalGetsModificationsComponent } from "../../modals/modal-gets-modifications/modal-gets-modifications.component";
// Globals
import { ENUMS } from "src/app/global/enums/enums";
import { GLOBAL } from "src/app/global/constants/global.const";
import { IDLETIMES, MAXTRIESPROPUESTA } from "../../global/constants/timeout";
import { IdleTimer } from "src/app/global/utils/IdleTimer";
import { KEYS } from "src/app/global/keys/keys";
import {
  LoginData,
  PlantData,
  PlantList,
} from "src/app/global/models/login-data.model";
import { MESSAGES } from "src/app/global/constants/messages.const";
import { SessionService } from "src/app/global/services/session.service";
import { SharedDataService } from "src/app/global/services/shared-data.service";
import { UtilsService } from "src/app/global/utils/utils.service";
// Components
import { MinuteCellComponent } from "./components/minute-cell/minute-cell.component";
import { OrdenNoProgramadaComponent } from "./components/orden-no-programada/orden-no-programada.component";
import { OrdenProgramadaComponent } from "./components/orden-programada/orden-programada.component";
import { PivotElementComponent } from "./components/pivot-element/pivot-element.component";
import { ShadowHighlightComponent } from "./components/shadow-highlight/shadow-highlight.component";
import { ShiftComponent } from "./components/shift/shift.component";
// Models
import {
  ConfirmChangeTurnDataRequest,
  GetAllMsgRequest,
  GetPlanRequest,
  NewProposalDataRequest,
  PostPlanRequest,
  RegisterShiftTruckDataRequest,
  UnconfirmDataRequest,
  UnsubscribeTurn,
} from "./models/requests.model";
import { SavePlanResponse } from "./models/responses.model";
import { TruckData, GridData, TurnChangeData } from "./models/general.model";
// Others
import { ApiProgramadorService } from "./services/api-programador.service";
import { DICTIONARY } from "./enums/dictionary.enum";
import { HeadersNotProgrammedOrders } from "./constants/headers-not-programmed-orders.const";
import { ListadoMotivos } from "./interfaces/motivos-distribucion";
import { ListadoMotivosDesc } from "../../pages/programador/interfaces/motivos-desconfirmacion";
import { PROGRAMADOR_CONST } from "./constants/programador.const";
import { ProgramadorFunctions } from "./functions/programador.functions";
import { environment } from "../../../environments/environment";
import { SubscribersProgramadorServices } from "./services/subscribers.programador.services";
import { SolicitudAlterarTurno } from "./interfaces/turnos.interfaces";
import { PedidoService } from "./services/pedidos.service";
import { ModificacionesTurnosService } from "./services/modificaciones-turnos.service";
import { DatosPlantaFecha } from "./interfaces/datos-planta-fecha";
import { ObjetoModificacion } from "./interfaces/respuesta-api-modificaciones";
import { SolicitudConfirmarLectura } from "./interfaces/solicitud-confirmar-lectura";
import { PLANTAS } from "./constants/plantas.const";

// This lets me use jquery
declare var $: any;
const wait = (ms: number) => new Promise((res) => setTimeout(res, ms));

@Component({
  selector: "app-programador",
  templateUrl: "./programador.component.html",
  styleUrls: ["./programador.component.css"],
})
@Injectable({
  providedIn: "root",
})
export class ProgramadorComponent implements OnInit, OnDestroy {
  @ViewChild("modalCleanButton", { static: false, read: ViewContainerRef })
  containerCleanButton;
  @ViewChild("modalCombinarPedido", { static: false, read: ViewContainerRef })
  containerCombinarPedido;
  @ViewChild("modalDetailsOrder", { static: true, read: ViewContainerRef })
  containerDetailsOrder;
  @ViewChild("modalDetailsOrderCombinado", {
    static: false,
    read: ViewContainerRef,
  })
  containerDetailsOrderCombinado;
  @ViewChild("modalDetailsTruck", { static: false, read: ViewContainerRef })
  containerDetailsTruck;
  @ViewChild("modalInfoLeyenda", { static: false, read: ViewContainerRef })
  modalInfoLeyenda;
  @ViewChild("modalLargeOrderInfo", { static: false, read: ViewContainerRef })
  modalLargeOrderInfo;
  @ViewChild("modalMontarPedido", { static: false, read: ViewContainerRef })
  modalMontarPedido;
  @ViewChild("modalNoProgramedOrdersComments", {
    static: false,
    read: ViewContainerRef,
  })
  containerNoProgramedOrdersComments;
  @ViewChild("modalShiftTruck", { static: false, read: ViewContainerRef })
  containerShiftTruck;
  @ViewChild("shadowHighlightDL", { static: false, read: ViewContainerRef })
  shadowHighlightDL;
  @ViewChild("shadowHighlightDR", { static: false, read: ViewContainerRef })
  shadowHighlightDR;
  @ViewChild("shadowHighlightUL", { static: false, read: ViewContainerRef })
  shadowHighlightUL;
  @ViewChild("shadowHighlightUR", { static: false, read: ViewContainerRef })
  shadowHighlightUR;
  @ViewChild("modalGetsModifications", {
    static: false,
    read: ViewContainerRef,
  })
  containerGetsModifications;

  // pivot references. Pivot helps to give coord to shadow higlight when drag a programmed order
  @ViewChild("pivotDL", { static: false, read: ViewContainerRef }) pivotDLRef;
  @ViewChild("pivotUL", { static: false, read: ViewContainerRef }) pivotULRef;

  VersionAux = 0;
  Version: any;
  EMessage: any;
  ERc: any;
  alerts: any;
  backButtonOBS: Subscription;
  cellMatched: string;
  checkNuevaPropuesta = false;
  colname: string;
  componentRefCleanButton: any;
  componentRefCombinarPedido: any;
  componentRefDetailTruck: any;
  componentRefInfoModal: any;
  componentRefMontarPedido: any;
  componentRefNoProgramedOrdersComment: any;
  componentRefOrderCombinadoModal: any;
  componentRefGetsModificationsModal: any;
  componentRefOrderModal: any;
  componentRefSessionExpModal: any;
  componentRefShiftTruck: any;
  componentRefShowChangePlantWarningButton: any;
  componentReflargeOrderModal: any;
  confirmDeconfirmFlag = false;
  confirmEnded: boolean;
  count_no_programados: number;
  count_programados: number;
  diccionario: any;
  environment = environment;
  errorLoading: boolean;
  errorMsg: any;
  fetchDataIsReady: boolean;
  fetchMessagesIsReady: boolean;
  fetchResultCleanIsReady: boolean;
  fetchTruckDataIsReady: boolean;
  filtroCamion = null;
  filtrosCamion = PROGRAMADOR_CONST.FILTROS_CAMION;
  finalHeight: number;
  gridData: GridData[];
  gridReadyToClick: boolean;
  headersNotProgrammedOrders = HeadersNotProgrammedOrders;
  heigthNoProgramados: number;
  highLightColor: string;
  hoursDay: number;
  hoursTomorrow: number;
  hoursYesterday: number;
  indexFromSortButtons: number;
  inptuSelectedPlant: any;
  implementsNewFlow = PLANTAS;
  loginData: any; // { user: string; name: string; token: string; };
  matrizDuraciones: any = [];
  messages: any;
  messagesReady: boolean;
  messages_combo: any;
  minuteCells: any;
  modelDate: any;
  montarPedidoFlag: boolean;
  noProgrammedOrderElements: any;
  overlapped_dict: any;
  pedidos: any;
  pedidosLoaded: boolean;
  pivotDL: any;
  pivotReference: {};
  pivotUL: any;
  pixelUnitTime: number;
  plannedOrderComponentList: any;
  plantData: DatosPlantaFecha; // { code: string; name: string; date: string; };
  plantsList: any;
  readySaveBeforeConfirm: boolean;
  returnClassAsc: {};
  returnClassDesc: {};
  screenHeight: number;
  screenResolution: string;
  searchText: {};
  searchedTruck: string;
  seleccionPedidos: boolean;
  selectedPlant: string;
  sessionAdmin: string = "";
  sessionEditPermission: boolean = false;
  shadowDL: any;
  shadowDR: any;
  shadowUL: any;
  shadowUR: any;
  showProposalButton: boolean; //boton para propuesta
  sortOrder: string;
  spinnerText: string;
  sqsResultPropuestaTries: number;
  stopSqsMessages: boolean = false; // variable para cortar el llamado de sqsResultMessages
  timePlantClient: any[];
  timer; //instancia clase IdleTimer
  timestamp: number;
  timestampGuardar: number;
  truckIdModalShiftSwitch: string;
  trucks: any[];
  trucksLoaded: boolean;
  unitTime: number;
  vehicleDown: any = [];
  windowHidden: boolean;
  modificationsStatus: boolean;
  finalSuscribe: boolean = true;
  dataActualizaciones = <ObjetoModificacion[]>[];
  dataDesconfirmar = [];
  updateModificaciones: boolean = true;
  // Definir un diccionario de mapeo para establecer el orden personalizado
  orderMapping: Record<string, number> = {
    ZEMG: 0,
    ZPPC: 1,
    ZCHG: 2,
    ZSTD: 3,
  };
  private destinoDefault: number = 1234; //valor utilizado para getDefaultValues() como destino
  private sqsIterations = { msg: "start", count: 0 }; // numero de consultas que se realizan a consumer-sqs
  private sqsIterationsTrucks = { msg: "start", count: 0 }; // numero de consultas que se realizan a consumer-sqs
  private tpoCarga: number; //Valores serán asignados según planta, consultando en la planta inicial seleccionada o bien cuando se cambie de planta
  private tpoDesca: number; //el método es getDefaultValues y se llama durante OnInit y cuando se llame al método update()
  private triesLimit: number = 45; // cantidad máxima de intentos a realizar. En caso de querer disminuir o aumentar, cambiar ESTE valor.
  private unconfirmProcess: boolean = false;
  private modificacionesTurnosSubscription: any;

  constructor(
    @Optional() private ref: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private modalService: NgbModal,
    private router: Router,
    private sessionService: SessionService,
    private sharedService: SharedDataService,
    private utils: UtilsService,
    public SpinnerService: NgxSpinnerService,
    public api: ApiProgramadorService,
    public programadorFunctions: ProgramadorFunctions,
    private subscribersProgramadorServices: SubscribersProgramadorServices,
    private pedidosService: PedidoService,
    private modificacionesTurnos: ModificacionesTurnosService
  ) {
    this.onResize();
    // pixels representing 30 minutes;
    // this will determine the witdh of each cell at minimum resolution
    // this.pixelUnitTime = 10;//12;
    // var ratio = 0.8
    var ratio = PROGRAMADOR_CONST.RATIO;
    this.pixelUnitTime = Math.floor((screen.width * ratio) / 100);
    // En minutos. Si unit time es 30, son 30 minutos la resolucion de la celda
    this.unitTime = PROGRAMADOR_CONST.UNIT_TIME;
    this.hoursYesterday = PROGRAMADOR_CONST.HOURS_YESTERDAY;
    this.hoursDay = PROGRAMADOR_CONST.HOURS_DAY;
    this.hoursTomorrow = PROGRAMADOR_CONST.HOURS_TOMORROW;
    this.highLightColor = PROGRAMADOR_CONST.HIGH_LIGHT_COLOR;
    this.spinnerText = PROGRAMADOR_CONST.SPINNER_TEXT;
    this.modificacionesTurnosSubscription = this.modificacionesTurnos.onModificacionRecibida.subscribe(
      async (evento) => {
        await this.modificacionesTurnos.detenerServicio();
        await this.procesarModificacionRecibida(evento);
      }
    );
  }

  async ngOnInit() {
    this.noProgrammedOrderElements = [];
    this.plannedOrderComponentList = {};
    this.seleccionPedidos = false;
    this.modificationsStatus = false;
    this.updateModificaciones = true;
    this.componentRefOrderCombinadoModal = undefined;
    this.componentRefGetsModificationsModal = undefined;
    // montar pedido
    if (this.componentRefOrderModal != null) {
      this.componentRefOrderModal.instance = null;
    }
    this.componentRefOrderModal = null;
    this.shadowUL = undefined;
    this.shadowUR = undefined;
    this.shadowDL = undefined;
    this.shadowDR = undefined;
    this.pivotDL = undefined;
    this.pivotUL = undefined;
    this.timestamp = -1;
    this.timestampGuardar = 0;
    this.screenResolution = screen.width + " x " + screen.height;
    this.errorLoading = false;
    this.alerts = [];
    // the list of minute cells. Each minute cell will add itself to the parent list when created
    this.minuteCells = [];
    this.searchText = {};
    this.returnClassAsc = {};
    this.returnClassDesc = {};
    this.dataActualizaciones = [];
    // temporary parameters
    this.gridReadyToClick = false;
    this.loginData = this.sharedService.getLoginInfo();
    this.plantData = this.sharedService.getPlantInfo();
    this.plantsList = this.sharedService.getPlantList();
    if (
      this.plantData == undefined ||
      sessionStorage.getItem("plantInfo") != null
    ) {
      const plantInfo: PlantData = this.sessionService.getPlantInfo();
      const dateData = {
        year: Number(plantInfo.date.split("-")[2]),
        month: Number(plantInfo.date.split("-")[1]),
        day: Number(plantInfo.date.split("-")[0]),
      };
      this.sharedService.setPlantInfo(plantInfo.name, plantInfo.code, dateData);
      this.plantData = this.sharedService.getPlantInfo();
      const plantList: PlantList[] = this.sessionService.getPlantList();
      this.sharedService.setPlantList(plantList);
      this.plantsList = this.sharedService.getPlantList();
      const loginData: LoginData = this.sessionService.getLoginData();
      this.sharedService.setValidatedLogin(
        loginData.user_name,
        loginData.user_pass,
        loginData.plantCode,
        loginData.bearer,
        loginData.roles,
        loginData.isAdmin
      );
      this.loginData = this.sharedService.getLoginInfo();
      const distrMotives: ListadoMotivos =
        this.sessionService.getDistrMotives();
      this.sharedService.setDistrMotives(distrMotives);
      const ordersMotives: ListadoMotivosDesc =
        this.sessionService.getOrdersMotives();
      this.sharedService.setOrdersMotives(ordersMotives);
      this.messages_combo = this.sharedService.getOrdersMotives();
      this.getDefaultValues(
        this.plantData.code,
        this.destinoDefault,
        this.plantData.code
      );
      this.checkProposalDate();
      this.manageSession();

      this.timer = new IdleTimer({
        timeout: IDLETIMES.timeout, //tiempo de expiración.Seguir la variable para ver. Actualmente en dev: 2min
        user: this.loginData.user_name,
        adviceTime: () => {
          let advice;
          const modalRef = this.modalService.open(
            ModalCloseSessionProgrammateComponent,
            {
              size: "lg",
              backdrop: "static",
              keyboard: false,
              centered: true,
            }
          );
          modalRef.componentInstance.time = IDLETIMES.countdown;
          advice = modalRef.result.then((val) => val);
          return advice;
        },
        onTimeout: async () => {
          // this.plantData.name = 'Sesión expirada'
          this.sqsResultPropuestaTries = 70;
          this.openAlert(
            "warning",
            "Intentos de obtener nueva Propuesta alcanzó el límite de intentos permitidos"
          );
          this.SpinnerService.show();
          if (
            this.sessionAdmin === this.loginData.user_name &&
            this.sessionEditPermission
          ) {
            let response = await this.endAdminSession();
            if (response["code"] === 200) {
              this.router.navigate(["./selector"]);
            }
          } else {
            this.backSelector();
          }
        },
        hiddenPage: this.windowHidden,
      });

      this.backButtonClick();
    }

    this.selectedPlant = this.plantData.name;
    this.modelDate = {
      year: Number(this.plantData.date.split("-")[2]),
      month: Number(this.plantData.date.split("-")[1]),
      day: Number(this.plantData.date.split("-")[0]),
    };
    // obtains the trucks and orders (programmed and not programmed)
    this.getData();
    this.generateGridData();
    await this.modificacionesTurnos.detenerServicio();
    this.modificacionesTurnos.cambiarPlantaFecha(
      this.plantData,
      this.loginData.user_name
    );
    this.modificacionesTurnos.iniciarServicio();
    this.doRefreshSubscriptions();

    

  }

  private async procesarModificacionRecibida(
    actualizaciones: ObjetoModificacion[]
  ) {
    if (!this.pedidosLoaded || !this.trucksLoaded) {
      this.modificacionesTurnos.iniciarServicio();
      return;
    }
    this.dataActualizaciones = actualizaciones;
    this.modificationsStatus = true;
    if (!this.finalSuscribe || !this.pedidosLoaded || !this.trucksLoaded) {
      this.modificacionesTurnos.iniciarServicio();
      return;
    }
    // Función para buscar los camiones que se darán de baja en los pedidos
    this.getPedidosDeleteStruct();
    let solicitudes = this.obtenerSolicitudesConfirmarLecturas();
    await this.modificacionesTurnos.confirmarMultiplesLecturas(solicitudes);
  }

  private obtenerSolicitudesConfirmarLecturas(): SolicitudConfirmarLectura[] {
    const cadenaSplit = this.plantData.date.split("-");
    const codigo_fecha = cadenaSplit.reverse().join("-");
    return this.dataActualizaciones.map(
      (modificacion: ObjetoModificacion) =>
        <SolicitudConfirmarLectura>{
          user: this.loginData.user_name,
          operation: "update",
          payload: {
            codigo_planta: this.plantData.code,
            codigo_fecha: codigo_fecha,
            evento: modificacion.evento,
          },
        }
    );
  }

  private doRefreshSubscriptions(): void {
    this.subscribersProgramadorServices
      .modalToProgramadorObservable()
      .subscribe((refresh) => {
        if (!this.pedidosLoaded || !this.trucksLoaded) {
          return;
        }
        this.SpinnerService.show();
        if (this.dataDesconfirmar.length > 0) {
          this.deconfirm(this.dataDesconfirmar, { use: false }, true);
        } else {
          this.save();
          this.pedidosLoaded = false;
          this.trucksLoaded = false;
        }
        this.update();
        this.modificacionesTurnos.iniciarServicio();
      });
  }

  ngOnDestroy(): void {
    this.modificacionesTurnos.detenerServicio();
    this.modificacionesTurnosSubscription.unsubscribe();
    this.backButtonOBS.unsubscribe();
    this.finalSuscribe = false;
  }

  /**
   * Listener para detectar el cambio de tamaño de la ventana y recalcular largo del view
   * @param event
   */
  @HostListener("window:resize", ["$event"])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.finalHeight = this.screenHeight - GLOBAL.CALC_WHITE_SPACE_VAL;
  }

  @HostListener("click", ["$event"])
  limpiarSeleccionHandler(event: MouseEvent) {
    if (event.shiftKey) {
      return;
    }
    if (this.confirmEnded != undefined && !this.confirmEnded) {
      // si readySaveBeforeConfirm es falso, es porque se esta mandando a
      // confirmar y no se debe borrar aun la seleccion
      return;
    }

    var pedidos_seleccionados = this.pedidos.filter(
      (e) => e.WEB_SELECTED != undefined
    );
    pedidos_seleccionados.forEach((e) => {
      e.WEB_SELECTED.classList.remove("selected");
      delete e.WEB_SELECTED;
    });
    this.seleccionPedidos = false;
  }

  @HostListener("document:visibilitychange", ["$event"])
  checkVisibility($event) {
    this.windowHidden = document.hidden;
  }

  /**
   * ve si es que no hay pedidos seleccionados. Si hay cero pedidos, this.seleccionPedidos es false
   * @returns
   */
  checkSeleccionadosCero() {
    var pedidos_seleccionados = this.pedidos.filter(
      (e) => e.WEB_SELECTED != undefined
    );
    if (pedidos_seleccionados.length === 0) {
      this.seleccionPedidos = false;
    }
    return;
  }

  setPivotReference(
    coordenada: string,
    left: string,
    right: string,
    top: string,
    bottom: string
  ) {
    if (this.pivotReference == undefined) {
      this.pivotReference = {};
    }
    this.pivotReference[coordenada] = {
      left: left + "px",
      right: right + "px",
      top: top + "px",
      bottom: bottom + "px",
    };
  }

  ngAfterViewInit() {
    this.SpinnerService.show();
  }

  setPlannedObject(plannedOrderComponent: any) {
    this.plannedOrderComponentList[plannedOrderComponent.plannedOrder.VBELN] =
      plannedOrderComponent;
  }

  /**
   * vemos si los campos comentarios o confirmado vienen en los datos. Si no, los creamos y se guardaran
   * en la bd con estos campos adicionales
   */
  completarCamposNoDefinidos() {
    this.pedidos.forEach((e) => {
      if (!e.hasOwnProperty("WARNINGS")) {
        _.set(e, "WARNINGS", "N");
      }
      if (!e.hasOwnProperty("UNSUBSCRIBE_COMMENT")) {
        _.set(e, "UNSUBSCRIBE_COMMENT", "");
      }
      if (!e.hasOwnProperty("UNSUBSCRIBE_CODE")) {
        _.set(e, "UNSUBSCRIBE_CODE", "");
      }
      if (!e.hasOwnProperty("UNCONFIRM_COMMENT")) {
        _.set(e, "UNCONFIRM_COMMENT", "");
      }
      if (!e.hasOwnProperty("UNCONFIRM_CODE")) {
        _.set(e, "UNCONFIRM_CODE", "");
      }
      if (!e.hasOwnProperty("ERRORS")) {
        _.set(e, "ERRORS", "N");
      }
      if (!e.hasOwnProperty("HIGHLIGHTED")) {
        _.set(e, "HIGHLIGHTED", "N");
      }
      if (!e.hasOwnProperty("HIGHLIGHTED")) {
        _.set(e, "HIGHLIGHTED", "N");
      }
      if (!e.hasOwnProperty("WARNING_MESSAGE")) {
        _.set(e, "WARNING_MESSAGE", " ");
      }
      if (!e.hasOwnProperty("ERROR_MESSAGE")) {
        _.set(e, "ERROR_MESSAGE", " ");
      }
    });

    this.trucks.forEach((e) => {
      if (!e.hasOwnProperty("HIGHLIGHTED")) {
        _.set(e, "HIGHLIGHTED", "N");
      }
    });
  }

  completarCamposNoDefinidosPedidos() {
    this.pedidos.forEach((e) => {
      if (!e.hasOwnProperty("WARNINGS")) {
        _.set(e, "WARNINGS", "N");
      }
      if (!e.hasOwnProperty("UNSUBSCRIBE_COMMENT")) {
        _.set(e, "UNSUBSCRIBE_COMMENT", "");
      }
      if (!e.hasOwnProperty("UNSUBSCRIBE_CODE")) {
        _.set(e, "UNSUBSCRIBE_CODE", "");
      }
      if (!e.hasOwnProperty("UNCONFIRM_COMMENT")) {
        _.set(e, "UNCONFIRM_COMMENT", "");
      }
      if (!e.hasOwnProperty("UNCONFIRM_CODE")) {
        _.set(e, "UNCONFIRM_CODE", "");
      }
      if (!e.hasOwnProperty("ERRORS")) {
        _.set(e, "ERRORS", "N");
      }
      if (!e.hasOwnProperty("HIGHLIGHTED")) {
        _.set(e, "HIGHLIGHTED", "N");
      }
      if (!e.hasOwnProperty("HIGHLIGHTED")) {
        _.set(e, "HIGHLIGHTED", "N");
      }
      if (!e.hasOwnProperty("WARNING_MESSAGE")) {
        _.set(e, "WARNING_MESSAGE", " ");
      }
      if (!e.hasOwnProperty("ERROR_MESSAGE")) {
        _.set(e, "ERROR_MESSAGE", " ");
      }
    });
  }

  completarCamposNoDefinidosVehiculos() {
    this.trucks.forEach((e) => {
      if (!e.hasOwnProperty("HIGHLIGHTED")) {
        _.set(e, "HIGHLIGHTED", "N");
      }
    });
  }

  darDeAltaTurno(solicitud: SolicitudAlterarTurno) {
    const confirm_truck_data: TruckData = {
      Werks: parseInt(this.plantData.code),
      Vehicle: solicitud.truckId,
      TurnoFechaDesde: solicitud.fechaIni,
      TurnoHoraDesde: solicitud.horaIni,
      TurnoFechaHasta: solicitud.fechaFin,
      TurnoHoraHasta: solicitud.horaFin,
      Motivos: solicitud.message_code,
    };
    const dataCambioTurno: TurnChangeData = {
      use: true,
      truckData: confirm_truck_data,
      darAlta: true,
      shift: solicitud.shift,
    };
    this.confirmCambioTurno(dataCambioTurno);
  }

  darDeBajaTurno(solicitud: SolicitudAlterarTurno) {
    // data para obtener la lista de pedidos del camion
    this.SpinnerService.show();
    const dataReq: UnsubscribeTurn = {
      truck: solicitud.truckId,
      fecha_ini: solicitud.fechaIni,
      hora_ini: solicitud.horaIni,
      fecha_fin: solicitud.fechaFin,
      hora_fin: solicitud.horaFin,
      codigo_planta: this.plantData.code,
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
    };

    let response = this.api.listaPedidosTruck(dataReq);

    response.subscribe(
      (data: { retCode: any }) => {
        const startAsync = async (callback) => {
          await wait(1000);
          callback();
        };
        startAsync((text) => {
          // LISTA PEDIDOS NOS RESPONDE
          if (parseInt(data.retCode, 10) === 200) {
            const lista_pedidos_camion = data["data"]["item"];
            // GENERAMOS LA INFO PARA DESCONFIRMAR EL CAMION
            const deconfirm_truck_data: TruckData = {
              Werks: parseInt(this.plantData.code),
              Vehicle: solicitud.truckId,
              TurnoFechaDesde: solicitud.fechaIni,
              TurnoHoraDesde: solicitud.horaIni,
              TurnoFechaHasta: solicitud.fechaFin,
              TurnoHoraHasta: solicitud.horaFin,
              Motivos: solicitud.message_code,
            };

            const dataCambioTurno: TurnChangeData = {
              use: true,
              truckData: deconfirm_truck_data,
              darAlta: false,
              shift: solicitud.shift,
            };

            // SI LISTA DE PEDIDOS VIENE VACIA
            if (Object.entries(data["data"]).length === 0) {
              // obtenemos la lista de pedidos dentro del turno
              var pedidosNoConfirmadosEnTurnoABajar =
                this.getPedidosNoConfirmadosEnTurnoABajar(deconfirm_truck_data);

              if (pedidosNoConfirmadosEnTurnoABajar.length > 0) {
                var dataSave = {
                  use: true,
                  dataTruck: dataCambioTurno,
                  dataPedidos: pedidosNoConfirmadosEnTurnoABajar,
                  idPedidosABajar: [],
                };
                // guardamos para en caso de dar de baja turno, lo consideramos
                pedidosNoConfirmadosEnTurnoABajar.forEach((p) => {
                  dataSave.idPedidosABajar.push(p.VBELN);
                });
                let new_ped = this.pedidos.filter(
                  (p) => !dataSave.idPedidosABajar.includes(p.VBELN)
                );

                dataSave.dataPedidos = dataSave.dataPedidos.concat(new_ped);
                this.save(dataSave);
                // CUANDO DESCOMENTEMOS SAVE, DESCOMENTAMOS ESTA LINEA (ES LLAMADA POR SAVE)
                this.confirmCambioTurno(dataCambioTurno);
                // esta linea se debiera llamar desde guardar turnos con la info a bajar
              } else {
                // si no viene lista de pedidos solo desconfirmo camion
                this.confirmCambioTurno(dataCambioTurno);
              }
            } else {
              // SI NO VIENE VACIA LA LISTA DE PDEIDOS
              // DESCONFIRMO LOS PEDIDOS (dataDesconfirmar)
              // y al desconfifrmar, despues desconfirmo camion
              var dataDesconfirmar = [];

              lista_pedidos_camion.forEach((p) => {
                var infoDesconfirmar = {
                  VBELN: p.VBELN,
                  VBTYP: p.VBTYP,
                  MOTIVO: solicitud.message_code,
                  SUBRC: "",
                  MESSAGE: "",
                  TIPO: "T",
                  OBSERVACION: solicitud.comentarios,
                  OBJETO_PEDIDO: this.pedidos.filter(
                    (e) => e.VBELN === p.VBELN
                  ),
                };
                dataDesconfirmar.push(infoDesconfirmar);
              });
              // el desconfirmar, desconfirma los pedidos y cuando haya hecho la desconfirmacion
              // (respuesta asincrona), ejecuta la confirmacion del cambio de turno del camion
              // (con datos deconfirm_truck_data)
              this.deconfirm(dataDesconfirmar, dataCambioTurno);
            }

            // desconfirmar los pedidos
            // confirmar baja camion
          } else {
            this.SpinnerService.hide();
          }
          this.updateCountPedidos();
        });
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  getPedidosNoConfirmadosEnTurnoABajar(deconfirm_truck_data: TruckData) {
    return this.pedidosService.getPedidosNoConfirmadosEnTurnoABajar(
      deconfirm_truck_data,
      this.pedidos
    );
  }

  register_shift_truck(
    vehicle: string,
    reason: string,
    reason_code: any,
    tipo_operacion: string,
    comentarios: string
  ) {
    var dataBody: RegisterShiftTruckDataRequest = {
      plant_code: this.plantData.code,
      date: this.utils.getDateCodeFromDate(this.plantData.date), // check if format is yyyymmdd
      vehicle,
      reason,
      reason_code,
      tipo_operacion,
      comentarios,
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
    };

    let response = this.api.registerShiftTruckChange(dataBody);
    response.subscribe(
      (data: { retCode: any }) => {
        const startAsync = async (callback) => {
          await wait(1000);
          callback();
        };
        startAsync((text) => {
          this.updateCountPedidos();
        });
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  save(customData: any = { use: false }) {
    if (!this.sessionEditPermission) {
      return;
    }
    this.SpinnerService.show();
    var dataProgramada = this.pedidos; // guardamos todo. Los pedidos confirmados en sap, permaneceran inalterables.
    if (customData.use) {
      dataProgramada = customData.dataPedidos;
    }

    // se transforman ciertos datos para compatibilidad con sap
    var dataTransformedToSave = [];

    dataProgramada.forEach((pedido) => {
      var pt: any = this.utils.formatDataToSap(pedido);
      if (
        customData.idPedidosABajar != undefined &&
        customData.idPedidosABajar.includes(pt.VBELN)
      ) {
        pt.VEHICLE = " ";
      }
      dataTransformedToSave.push(pt);
    });

    if (this.confirmDeconfirmFlag != true) {
      this.timestamp = Math.floor(Date.now() / 1000);
    } else {
    }
    var dataSave: PostPlanRequest = {
      plan: dataTransformedToSave,
      timestamp: this.timestamp,
      codigo_planta: this.plantData.code,
      fecha: this.utils.dateToSapformat(this.plantData.date),
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
      version: this.Version, //this.VersionAux === 0 ? this.Version : this.VersionAux,
      listado: {},
    };
    this.Version = dataSave.timestamp;
    this.timestampGuardar = dataSave.version;
    dataSave.listado = this.programadorFunctions.addDistrList();
    let response = this.api.savePlan(dataSave);
    response.subscribe(
      (data: SavePlanResponse) => {
        this.ERc = data.ERc;
        this.EMessage = data.EMessage;
        this.showSapMessages();
        const startAsync = async (callback) => {
          await wait(1000);
          callback();
        };
        startAsync((text) => {
          if (
            !(
              this.readySaveBeforeConfirm !== undefined &&
              this.readySaveBeforeConfirm === false
            )
          ) {
            if (!this.unconfirmProcess && this.updateModificaciones) {
              this.SpinnerService.hide();
              this.updateModificaciones = true;
            } else {
              this.updateModificaciones = true;
            }
          }

          this.readySaveBeforeConfirm = true;
          if (parseInt(data.retCode, 10) === 200) {
            if (this.ERc === 0 || this.ERc === "0") {
              //Actualizamos la version con el timestamp en guardar
              this.Version = dataSave.timestamp;
              this.timestampGuardar = dataSave.version;
              // Si viene custom data y hay datatruck, confirmamos el turno
              if (customData.use) {
                if (customData.dataTruck !== undefined) {
                  this.confirmCambioTurno(customData.dataTruck);
                }
              }
              this.openAlert("success", this.EMessage);
            } else {
              this.openAlert("danger", this.EMessage);
            }
          } else {
            this.openAlert("danger", MESSAGES.PROGRAMADOR.ERROR_SAVE_PLAN);
          }
          this.updateCountPedidos();
        });
      },
      (err) => {
        if (!this.unconfirmProcess) {
          this.SpinnerService.hide();
        }
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Calcula la capacidad máxima de cada vehículo
   */
  addFullCapacitytoTruck() {
    this.trucks.forEach((truck) => {
      var arrCapacity = truck.capacity;
      var sum = 0;
      arrCapacity.forEach((elm) => {
        var elSplit = elm.split("/");
        var maxCapacity = elSplit[0] * 1;
        // asumimos que los compartimientos cuando vienen con flecha "/", vienen 2 capacidades
        if (elSplit.length > 1) {
          maxCapacity = Math.max(elSplit[0] * 1, elSplit[1] * 1);
        }
        sum += maxCapacity * 1;
      });
      truck.FULL_CAPACITY = sum;
    });
  }

  updateCountPedidos() {
    this.count_programados = this.pedidos.filter(
      (e) => e.VEHICLE.trim() != ""
    ).length;
    this.count_no_programados = this.pedidos.filter(
      (e) => e.VEHICLE.trim() == "" && e.ESTADO != "3"
    ).length;
  }

  processFetchCleanData(data: any) {
    this.ERc = data.ERc;
    this.EMessage = data.EMessage;
    this.showSapMessages();

    if (data.errorMessage != null) {
      this.showError(data.errorMessage);
    } else {
      // clean. Seguramente sera remplazado por algo con sqs
      this.Version = data.version;
      this.pedidos = this.ordenarPedidos(data);
      this.pedidosLoaded = true;
      this.updateCountPedidos();
      this.sortByEmergencyOrder();
      this.completarCamposNoDefinidos();
      this.addFullCapacitytoTruck();
      this.programadorFunctions.delDistrListReg();

      // lo usamos como un flag para indicar que los pedidos de la llamada asincrona estan listos
      this.errorLoading = false;

      this.pedidos.forEach((p) => {
        p.NAME1_KUNAG_CONCAT = p.KUNAG * 1 + ": " + p.NAME1_KUNAG;
        /*se agrega esto que viene de get plan*/
        if (p.CMGST === "B") {
          p.CMGST_TEXT = DICTIONARY.BLOQUEO_CREDITO;
        } else if (p.CMGST === "X") {
          p.CMGST_TEXT = DICTIONARY.BLOQUEO_ENTREGA;
        } else if (p.CMGST === "Z") {
          p.CMGST_TEXT = DICTIONARY.BLOQUEO_FACTURA;
        } else {
          p.CMGST_TEXT = DICTIONARY.SIN_BLOQUEO_CREDITO;
        }

        p.DURACION_TEXT = this.utils.hhmmssSeparateWithColons(
          p.DURACION_CALCULA
        );
      });
    }

    const startAsync = async (callback) => {
      await wait(1000);
      callback();
    };

    startAsync((text) => {
      this.emptyGrid();
      this.setPlannedOrderOnGrid();
      this.SpinnerService.hide();
      this.updateCountPedidos();
    });
  }

  getSqsResultClean(msg_id: string) {
    let response = this.api.getSqsResult(msg_id);
    response.subscribe(
      (data: any) => {
        if (typeof data.url == "string") {
          this.fetchResultCleanIsReady = true;
          let responseURL = this.api.getResponse(data.url);
          responseURL.subscribe((dataClean: any) => {
            this.SpinnerService.hide();
            this.processFetchCleanData(dataClean);
          });
        }
        this.SpinnerService.show();

        const startAsync = async (callback) => {
          await wait(3000);
          if (!this.fetchResultCleanIsReady) {
            this.getSqsResultClean(msg_id);
          }
        };
        startAsync((e) => {});
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  getSqsResultPropuesta(msg_id: string) {
    // fijamos en el browser cuanto estamos dispuestos a esperar, en caso que se demore mucho o simplemente algo pase.
    // 3*100 = 300 segundos por ejemplo. % minutos.
    var maxTimeSeconds =
      PROGRAMADOR_CONST.WAIT_SEC * PROGRAMADOR_CONST.MAX_TRIES;
    this.sqsResultPropuestaTries++;
    this.SpinnerService.show();
    let response = this.api.getSqsResult(msg_id);
    response.subscribe(
      (data: any) => {
        if (typeof data.url == "string") {
          this.fetchDataIsReady = true;
          let responseURL = this.api.getResponse(data.url);
          responseURL.subscribe((dataPropuesta: any) => {
            this.processFetchPropuesta(dataPropuesta);
          });
        }

        const startAsync = async (callback) => {
          await wait(PROGRAMADOR_CONST.WAIT_SEC * 1000);
          if (
            !this.fetchDataIsReady &&
            this.sqsResultPropuestaTries < MAXTRIESPROPUESTA
          ) {
            this.getSqsResultPropuesta(msg_id);
          } else {
            this.SpinnerService.hide();
            this.sqsResultPropuestaTries = 0;
            return;
          }
        };
        startAsync((e) => {});
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Espera a que los pedidos estén cargados para
   * unir los comentarios recibidos con el pedido correspondiente
   * @returns void
   */
  waitJoinPedidosComentarios() {
    if (this.pedidosLoaded) {
      this.joinPedidosComentarios();
      return;
    }

    const startAsync = async (callback) => {
      await wait(1000);
      callback();
    };
    startAsync((text) => {
      this.waitJoinPedidosComentarios();
    });
  }

  /**
   * Une un cada comentario con el pedido asociado
   */
  joinPedidosComentarios() {
    this.pedidos.forEach((ped) => {
      var msg = this.messages.filter((m) => m.IdPed.trim() == ped.VBELN.trim());
      if (msg.length > 0) {
        msg = msg[0];
        ped.COMENTARIOS = this.utils.extractText(msg.Text);
      }
    });
  }

  // data es lo de la tabla sqs
  processFetchPropuesta(data: any) {
    const startAsync = async (callback) => {
      await wait(1000);
      callback();
    };
    startAsync((text) => {
      if (parseInt(data.ret_code, 10) === 200) {
        this.SpinnerService.hide();
        this.replacePropuestaInPlan(data.pedidos);
        this.emptyGrid();
        this.setPlannedOrderOnGrid();
        this.openAlert("success", data.msg);

        this.pedidos.forEach((p) => {
          p.NAME1_KUNAG_CONCAT = p.KUNAG * 1 + ": " + p.NAME1_KUNAG;
        });
      }
      if (parseInt(data.ret_code, 10) === 500) {
        this.SpinnerService.hide();
        this.openAlert("warning", data.msg);
      }
      this.updateCountPedidos();
    });
  }

  // ------------------------------------------------------------------- //
  // --  Inicio Métodos Para obtener Pedidos, Vehículos y Comentarios -- //
  // ------------------------------------------------------------------- //

  /**
   * Inicia los procesos de obtención de Pedidos, Vehículos y Comentarios
   */
  getData() {
    this.SpinnerService.show();
    // el boton nueva propuesta se muestra solo
    if (this.plantData != undefined) {
      let solventesList = PROGRAMADOR_CONST.SOLVENTES_LIST;
      if (solventesList.includes(this.plantData.code)) {
        this.checkNuevaPropuesta = true;
      } else {
        this.checkNuevaPropuesta = false;
      }
    }

    this.noProgrammedOrderElements = [];
    // declara que el proceso de obtencion de pedidos esta listo
    this.fetchDataIsReady = false;
    this.fetchTruckDataIsReady = false;
    // declara que la variable this.pedidos esta cargada.
    this.pedidosLoaded = false;
    // declara que la variable this.trucks esta cargada.
    this.trucksLoaded = false;

    if (this.implementsNewFlow[this.plantData.code]) {
      // Obtener pedidos desde SAP y disponibilidad desde Dispomate
      this.getTrucks();
      this.NewGetOrders();
    } else {
      // Obtener pedidos y disponibilidad desde SAP
      this.getOrders();
    }

    // After send get data, inmediatly we try to get messages async
    this.loadMessages();
  }

  // ---------------------------------------------------- //
  // --  Métodos para obtención de Pedidos y Vehículos -- //
  // ---------------------------------------------------- //

  /**
   * Consulta por Pedidos y Vehículos
   */
  getOrders() {
    const body = {
      codigo_planta: [this.plantData.code],
      codigo_fecha: [this.utils.getDateCodeFromDate(this.plantData.date)],
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
    };
    const request = {
      function: "get_plan",
      request: body,
    };

    let ObtienePedidosResponse = this.api.sendSqs(request);

    ObtienePedidosResponse.subscribe(
      (data: { msg_queue_id: string }) => {
        const msg_queue_id = data.msg_queue_id;
        this.getSqsResultPlan(msg_queue_id);
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Espera por los resultados de Pedidos que vienen de SAP
   * @param msg_id string con el ID del mensaje de SQS
   */
  getSqsResultPlan(msg_id: string) {
    if (
      this.sqsIterations.count === this.triesLimit &&
      this.sqsIterations.msg === "end"
    ) {
      this.sqsIterations.count = 0;
    }

    this.SpinnerService.show();

    let response = this.api.getSqsResult(msg_id);
    response.subscribe(
      (data: any) => {
        if (typeof data.url == "string") {
          this.fetchDataIsReady = true;

          let responseURL = this.api.getResponse(data.url);
          responseURL.subscribe((dataPlanificacion: any) => {
            this.SpinnerService.hide();
            this.spinnerText = PROGRAMADOR_CONST.SPINNER_TEXT;
            this.processFetchData(dataPlanificacion);
          });
        }

        const startAsync = async (callback) => {
          await wait(3000);
          if (
            !this.fetchDataIsReady &&
            this.sqsIterations.count <= this.triesLimit
          ) {
            this.getSqsResultPlan(msg_id);
            this.sqsIterations.count++;
            this.sqsIterations.msg = "start";
          } else if (this.sqsIterations.count >= this.triesLimit) {
            this.sqsIterations.msg = "end";
            this.SpinnerService.hide();
            this.openAlert(
              "danger",
              "Solicitud máxima de intentos. No hay respuesta del servidor"
            );
            this.timer.cleanUp();
            this.router.navigate(["/selector"]);
          }
        };
        startAsync((e) => {});
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Procesa los Pedidos y Vehículos que vienen de SAP
   * @returns void
   */
  processFetchData(data: any) {
    this.ERc = data.ERc;
    this.EMessage = data.EMessage;

    if (this.ERc != 0) {
      // sessionStorage.setItem('selector_message',this.EMessage)
      this.stopSqsMessages = true;
      this.sharedService.setSelectorMessage(this.EMessage);
      this.endAdminSession().then((resp) => {});
      this.timer.cleanUp();
      this.router.navigate(["/selector"]);
      return;
    }

    if (data.errorMessage != null) {
      this.showError(data.errorMessage);
    } else {
      this.trucks = this.ordenarCamiones(data);
      this.vehicleDown = [];
      this.messages_combo = this.sessionService.getOrdersMotives();

      // get data sqs
      this.Version = data.version;
      this.pedidos = this.ordenarPedidos(data);
      this.pedidosLoaded = true;

      this.pedidos.forEach((elm) => {
        // se agrega concatenacion de cliente (kunag) con su descripcion (name1_kunag)
        elm.NAME1_KUNAG_CONCAT = elm.KUNAG * 1 + ": " + elm.NAME1_KUNAG;

        if (elm.CMGST == "B") {
          elm.CMGST_TEXT = DICTIONARY.BLOQUEO_CREDITO;
        } else if (elm.CMGST === "X") {
          elm.CMGST_TEXT = DICTIONARY.BLOQUEO_ENTREGA;
        } else if (elm.CMGST === "Z") {
          elm.CMGST_TEXT = DICTIONARY.BLOQUEO_FACTURA;
        } else {
          elm.CMGST_TEXT = DICTIONARY.SIN_BLOQUEO_CREDITO;
        }

        elm.DURACION_TEXT = this.utils.hhmmssSeparateWithColons(
          elm.DURACION_CALCULA
        );
      });
      // si los pedidos vienen por error de sap con un pos_agrupador distinto de 0 y vienen con agrupador, se los sacamaos
      this.cleanPosAgrupadorIfNotAgrupador();
      this.updateCountPedidos();
      this.sortByEmergencyOrder();
      this.completarCamposNoDefinidos();
      this.addFullCapacitytoTruck();

      // lo usamos como un flag para indicar que los pedidos de la llamada asincrona estan listos
      this.errorLoading = false;
    }

    const startAsync = async (callback) => {
      await wait(1000);
      callback();
    };
    startAsync((text) => {
      this.emptyGrid();
      this.setPlannedOrderOnGrid();
      this.showSapMessages();
      this.SpinnerService.hide();
      this.updateCountPedidos();
    });
  }

  ordenarCamiones(data) {
    return data.truck.sort((a, b) => {
      // Ordena por fuelTypes en este orden
      const orderMap = {
        "Livianos Normal": 1,
        "Livianos Aviación": 2,
        Pesados: 3,
      };
      const fuelTypesComparison = orderMap[a.fuelTypes] - orderMap[b.fuelTypes];

      if (fuelTypesComparison !== 0) {
        return fuelTypesComparison;
      }

      // Si fuelTypes es igual, ordena por capacidad
      const sumA = a.capacity.reduce((acc, val) => {
        const parts = val.split("/").map((str) => parseInt(str));
        const maxCapacity = Math.max(...parts);
        return acc + maxCapacity;
      }, 0);

      const sumB = b.capacity.reduce((acc, val) => {
        const parts = val.split("/").map((str) => parseInt(str));
        const maxCapacity = Math.max(...parts);
        return acc + maxCapacity;
      }, 0);

      return sumA - sumB;
    });
  }

  // -------------------------------------------- //
  // --  Métodos para obtención de Comentarios -- //
  // -------------------------------------------- //

  /**
   * Consulta por comentarios de pedidos a SAP
   */
  loadMessages() {
    this.messagesReady = false;
    this["responseLocationGetMessages"] = "";

    var data: GetAllMsgRequest = {
      plant_code: this.plantData.code,
      date: this.utils.yyyymmddToDateSap(
        this.utils.getDateCodeFromDate(this.plantData.date)
      ),
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
    };
    var request = {
      function: "get_all_msg",
      request: data,
    };

    let response = this.api.sendSqs(request);
    response.subscribe(
      (dataResponse: { msg_queue_id: string }) => {
        const msg_queue_id = dataResponse.msg_queue_id;

        this.getSqsResultMessage(msg_queue_id);
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Espera por los resultados de Comentarios de pedidos que vienen de SAP
   * @param msg_id string con el ID del mensaje de SQS
   */
  getSqsResultMessage(msg_id: string) {
    let response = this.api.getSqsResult(msg_id);
    response.subscribe(
      (data: any) => {
        if (typeof data.url == "string") {
          let responseURL = this.api.getResponse(data.url);
          responseURL.subscribe((dataMessage: any) => {
            this.processFetchMessages(dataMessage);
          });
        }

        const startAsync = async (callback) => {
          await wait(3000);
          if (
            !this.messagesReady &&
            !this.stopSqsMessages &&
            this.finalSuscribe
          ) {
            this.getSqsResultMessage(msg_id);
          }
        };
        startAsync((e) => {});
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Procesa los Comentarios de pedidos que vienen de SAP
   * @returns void
   */
  processFetchMessages(data: any) {
    this.messages = data;
    this.messagesReady = true;
    this.waitJoinPedidosComentarios();
  }

  // ---------------------------------------------------------------- //
  // --  Fin Métodos Para obtener Pedidos, Vehículos y Comentarios -- //
  // ---------------------------------------------------------------- //

  // -------------------------------------------------- //
  // --  Inicio Nuevos Métodos Integración Dispomate -- //
  // -------------------------------------------------- //

  // ---------------------------------------- //
  // --  Métodos para obtención de Pedidos -- //
  // ---------------------------------------- //

  /**
   * Realiza la consulta de Pedidos a SAP
   */
  NewGetOrders() {
    const body = {
      codigo_planta: this.plantData.code,
      codigo_fecha: this.utils.getDateCodeFromDate(this.plantData.date),
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
    };
    const request = {
      function: "obtiene_pedidos",
      request: body,
    };

    let ObtienePedidosResponse = this.api.sendSqs(request);

    ObtienePedidosResponse.subscribe(
      (data: { msg_queue_id: string }) => {
        const msg_queue_id = data.msg_queue_id;
        this.getSqsResultNewGetOrders(msg_queue_id);
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Espera por los resultados de Pedidos que vienen de SAP
   * @param msg_id string con el ID del mensaje de SQS
   */
  getSqsResultNewGetOrders(msg_id: string) {
    if (
      this.sqsIterations.count === this.triesLimit &&
      this.sqsIterations.msg === "end"
    ) {
      this.sqsIterations.count = 0;
    }

    this.SpinnerService.show();

    let response = this.api.getSqsResult(msg_id);
    response.subscribe(
      (data: any) => {
        if (typeof data.url == "string") {
          this.fetchDataIsReady = true;
          let responseURL = this.api.getResponse(data.url);
          responseURL.subscribe((dataPlanificacion: any) => {
            this.spinnerText = PROGRAMADOR_CONST.SPINNER_TEXT;
            this.processSqsResultNewGetOrders(dataPlanificacion);
          });
        }

        const startAsync = async (callback) => {
          await wait(3000);
          if (
            !this.fetchDataIsReady &&
            this.sqsIterations.count <= this.triesLimit
          ) {
            this.getSqsResultNewGetOrders(msg_id);
            this.sqsIterations.count++;
            this.sqsIterations.msg = "start";
          } else if (this.sqsIterations.count >= this.triesLimit) {
            this.sqsIterations.msg = "end";
            this.SpinnerService.hide();
            this.openAlert(
              "danger",
              "Solicitud máxima de intentos. No hay respuesta del servidor"
            );
            this.timer.cleanUp();
            this.router.navigate(["/selector"]);
          }
        };
        startAsync((e) => {});
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Procesa los Pedidos que vienen de SAP
   * @returns void
   */
  processSqsResultNewGetOrders(data: any) {
    //Obtenemos la versión actual desde SAP
    this.Version = data.version;
    this.ERc = data.ERc;
    this.EMessage = data.EMessage;

    if (this.ERc != 0) {
      this.stopSqsMessages = true;
      this.sharedService.setSelectorMessage(this.EMessage);
      this.endAdminSession().then((resp) => {});
      this.timer.cleanUp();
      this.router.navigate(["/selector"]);
      return;
    }

    if (data.errorMessage != null) {
      this.showError(data.errorMessage);
    } else {
      this.messages_combo = this.sessionService.getOrdersMotives();
      this.Version = data.version;
      this.pedidos = this.ordenarPedidos(data);

      this.pedidosLoaded = true;

      this.pedidos.forEach((pedido) => {
        // se agrega concatenacion de cliente (kunag) con su descripcion (name1_kunag)
        pedido.NAME1_KUNAG_CONCAT =
          pedido.KUNAG * 1 + ": " + pedido.NAME1_KUNAG;

        if (pedido.CMGST === "B") {
          pedido.CMGST_TEXT = DICTIONARY.BLOQUEO_CREDITO;
        } else if (pedido.CMGST === "X") {
          pedido.CMGST_TEXT = DICTIONARY.BLOQUEO_ENTREGA;
        } else if (pedido.CMGST === "Z") {
          pedido.CMGST_TEXT = DICTIONARY.BLOQUEO_FACTURA;
        } else {
          pedido.CMGST_TEXT = DICTIONARY.SIN_BLOQUEO_CREDITO;
        }

        pedido.DURACION_TEXT = this.utils.hhmmssSeparateWithColons(
          pedido.DURACION_CALCULA
        );
      });

      // si los pedidos vienen por error de sap con un pos_agrupador distinto de 0 y vienen con agrupador, se los sacamaos
      this.cleanPosAgrupadorIfNotAgrupador();
      this.updateCountPedidos();
      this.sortByEmergencyOrder();
      this.completarCamposNoDefinidosPedidos();

      // lo usamos como un flag para indicar que los pedidos de la llamada asincrona estan listos
      this.errorLoading = false;
    }

    const startAsync = async (callback) => {
      await wait(1000);
      callback();
    };
    startAsync(() => {
      this.emptyGrid();
      this.setPlannedOrderOnGrid();
      this.showSapMessages();
      if (this.trucksLoaded && this.pedidosLoaded) {
        this.SpinnerService.hide();
      }
      this.updateCountPedidos();
    });
  }

  // ------------------------------------------ //
  // --  Métodos para obtención de Vehículos -- //
  // ------------------------------------------ //

  /**
   * Realiza la consulta al Dispomate por la disponibilidad de Vehículos
   */
  getTrucks() {
    const body = {
      codigo_planta: this.plantData.code,
      codigo_fecha: this.utils.getDateWithHippens(this.plantData.date),
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
    };
    const request = {
      function: "obtiene_disponibilidad",
      request: body,
    };

    let ObtienePedidosResponse = this.api.sendSqs(request);

    ObtienePedidosResponse.subscribe(
      (data: { msg_queue_id: string }) => {
        const msg_queue_id = data.msg_queue_id;

        this.getSqsResultGetTrucks(msg_queue_id);
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );

   


  }

  /**
   * Espera por los resultados de Vehículos que vienen del Dispomate
   * @param msg_id string con el ID del mensaje de SQS
   */
  getSqsResultGetTrucks(msg_id: string) {
    if (
      this.sqsIterationsTrucks.count === this.triesLimit &&
      this.sqsIterationsTrucks.msg === "end"
    ) {
      this.sqsIterationsTrucks.count = 0;
    }

    let response = this.api.getSqsResult(msg_id);
    response.subscribe(
      (data: any) => {
        if (typeof data.url == "string") {
          this.fetchTruckDataIsReady = true;

          let responseURL = this.api.getResponse(data.url);
          responseURL.subscribe((dataPlanificacion: any) => {
            this.processSqsResultGetTrucks(dataPlanificacion);
          });
        }

        const startAsync = async (callback) => {
          await wait(3000);
          if (
            !this.fetchTruckDataIsReady &&
            this.sqsIterationsTrucks.count <= this.triesLimit
          ) {
            this.getSqsResultGetTrucks(msg_id);
            this.sqsIterationsTrucks.count++;
            this.sqsIterationsTrucks.msg = "start";
          } else if (this.sqsIterationsTrucks.count >= this.triesLimit) {
            this.sqsIterationsTrucks.msg = "end";
            this.SpinnerService.hide();
            this.openAlert(
              "danger",
              "Solicitud máxima de intentos. No hay respuesta del servidor"
            );
            this.timer.cleanUp();
            this.router.navigate(["/selector"]);
          }



          
        };
        startAsync((e) => {});
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Procesa los Vehículos que vienen de SAP
   * @returns void
   */
  processSqsResultGetTrucks(data: any) {
    if (data.ERc != 200) {
      this.stopSqsMessages = true;
      this.sharedService.setSelectorMessage(data.EMessage);
      this.endAdminSession().then((resp) => {});
      this.timer.cleanUp();
      this.router.navigate(["/selector"]);
      return;
    }

    if (data.EMessage != "OK") {
      this.showError(data.EMessage);
    } else {
      this.trucks = this.ordenarCamiones(data);
      this.trucksLoaded = true;
      this.vehicleDown = [];
      this.addFullCapacitytoTruck();
      this.completarCamposNoDefinidosVehiculos();

      //despues de obtener la data hay si ordenar
      this.sortTrucksByDefault();

    }

    const startAsync = async (callback) => {
      await wait(1000);
      callback();
    };
    startAsync(() => {
      if (this.trucksLoaded && this.pedidosLoaded) {
        this.SpinnerService.hide();
      }
    });
  }

  // ----------------------------------------------- //
  // --  Fin Nuevos Métdos Integración Dispomate --- //
  // ----------------------------------------------- //

  clean() {
    this.fetchResultCleanIsReady = false;
    this.pedidosLoaded = false;
    this.trucksLoaded = false;
    this.SpinnerService.show();
    let plantCode = this.plantData.code;
    let dateCode = this.utils.getDateCodeFromDate(this.plantData.date);
    var body: GetPlanRequest = {
      codigo_fecha: dateCode,
      codigo_planta: plantCode,
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
    };
    var request = {
      function: "clean",
      request: body,
    };
    let response = this.api.sendSqs(request);
    response.subscribe(
      (data: { msg_queue_id: string }) => {
        var msg_queue_id = data.msg_queue_id;
        this.getSqsResultClean(msg_queue_id);
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );

    // actualizamos los mensajes tambien
    this.loadMessages();
  }

  ordenarPedidos(data) {
    return data.pedidos.sort((a, b) => {
      const orderA = this.orderMapping[a.BSARK] || Number.MAX_SAFE_INTEGER;
      const orderB = this.orderMapping[b.BSARK] || Number.MAX_SAFE_INTEGER;
      return orderA - orderB;
    });
  }

  sortByEmergencyOrder() {
    var pedidosEmergencia = this.pedidos.filter((e) => e.BSARK === ENUMS.ZEMG);
    var pedidosNoEmergencia = this.pedidos.filter(
      (e) => e.BSARK !== ENUMS.ZEMG
    );

    for (var i = 0; i < pedidosEmergencia.length; i++) {
      pedidosNoEmergencia.unshift(pedidosEmergencia[i]);
    }

    this.pedidos = pedidosNoEmergencia;
  }

  showError(error) {
    this.errorLoading = true;
    this.errorMsg = error;
  }

  cellInShift(minuteCell, shift, truckId) {
    var cellTime = this.utils.hhmmTohhmmss(minuteCell.hour, minuteCell.minute);
    var cellDate = this.utils.dateToyyyymmdd(minuteCell.date);
    var shiftDateIni = this.utils.dateToyyyymmdd(shift.dayInit);
    var shiftTimeIni = this.utils.hhmmTohhmmss(
      shift.hourInit,
      shift.minutesInit
    );
    var shiftDateEnd = this.utils.dateToyyyymmdd(shift.dayEnd);
    var shiftTimeEnd = this.utils.hhmmTohhmmss(shift.hourEnd, shift.minutesEnd);
    var timestampCell = this.utils.dbDateTimeToTimestamp(cellDate, cellTime);
    var timestampShiftIni = this.utils.dbDateTimeToTimestamp(
      shiftDateIni,
      shiftTimeIni
    );
    var timestampShiftEnd = this.utils.dbDateTimeToTimestamp(
      shiftDateEnd,
      shiftTimeEnd
    );

    if (
      timestampCell >= timestampShiftIni &&
      timestampCell <= timestampShiftEnd &&
      minuteCell.truck == truckId
    ) {
      return true;
    }
    return false;
  }

  checkConfirmedInShift(shift, truckId) {
    var cellsInShift = this.minuteCells.filter((e) =>
      this.cellInShift(e, shift, truckId)
    );
    var existeConfirmadoEnTurno = false;
    cellsInShift.forEach((e) => {
      if (e.getProgrammedOrder() != false) {
        if (
          this.programadorFunctions.esPedidoConfirmado(
            e.getProgrammedOrder().plannedOrder
          )
        ) {
          existeConfirmadoEnTurno = true;
        }
      }
    });
    return existeConfirmadoEnTurno;
  }

  openOrderDetailsModal(order: any) {
    if (this.componentRefOrderModal == null) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalDetailsOrderComponent
        );
      this.componentRefOrderModal =
        this.containerDetailsOrder.createComponent(componentFactory); // la programacion asociada a la celda
      this.componentRefOrderModal.instance.setProgramador(this);
    }
    this.componentRefOrderModal.instance.setOrderObject(order);
  }

  openOrderCombinedDetailsModal(order: any) {
    if (this.componentRefOrderCombinadoModal == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalDetallePedidoCombinadoComponent
        );
      // la programacion asociada a la celda
      this.componentRefOrderCombinadoModal =
        this.containerDetailsOrderCombinado.createComponent(componentFactory);
      this.componentRefOrderCombinadoModal.instance.setProgramador(this);
    }
    this.componentRefOrderCombinadoModal.instance.setOrderList(order);
    this.componentRefOrderCombinadoModal.instance.show();
  }

  openTruckDetailsModal(truck) {
    if (this.componentRefDetailTruck == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalDetailsTruckComponent
        );
      this.componentRefDetailTruck =
        this.containerDetailsTruck.createComponent(componentFactory);
    }
    this.componentRefDetailTruck.instance.setProgramador(this);
    this.componentRefDetailTruck.instance.setTruck(truck);
    this.componentRefDetailTruck.instance.show();
  }

  openShiftTruckModal(
    truckId: string,
    shift: any,
    shiftComponent: ShiftComponent
  ) {
    // seteamos el shift activado o desactivado dependiendo si el camion se encuentra abajo o no
    // hay que ver para turnos
    var active = false;
    this.vehicleDown.forEach((vd) => {
      var c2 = vd.TurnoFechaHasta == this.utils.swapDate(shift.dayEnd);
      var c1 = vd.TurnoFechaDesde == this.utils.swapDate(shift.dayInit);
      var c3 =
        vd.TurnoHoraDesde ==
        this.utils.getHHMMSS(
          shift.hourInit,
          shift.minutesInit,
          shift.secondsInit
        );
      var c4 =
        vd.TurnoHoraHasta ==
        this.utils.getHHMMSS(shift.hourEnd, shift.minutesEnd, shift.secondsEnd);
      var c5 = vd.Vehicle == truckId;

      if (c1 && c2 && c3 && c4 && c5) {
        active = true;
      }
    });
    if (active) {
      shift.active = false;
    } else {
      shift.active = true;
    }

    if (this.componentRefShiftTruck == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalShiftSwitchComponent
        );
      this.componentRefShiftTruck =
        this.containerShiftTruck.createComponent(componentFactory);
    }
    this.componentRefShiftTruck.instance.setTruckId(truckId);
    // el turno del camion
    this.componentRefShiftTruck.instance.setShift(shift);
    // la componente que representa el checkbox del turno.
    this.componentRefShiftTruck.instance.setShiftComponent(shiftComponent);
    // seteamos el programador para que pueda hacer uso de sus estructuras
    this.componentRefShiftTruck.instance.setProgramador(this);

    this.componentRefShiftTruck.instance.show();
  }

  // las dos son ordenes. matchOrder es la orden sobre la que choca la orden que arrastramos. Match order podria ser
  // eventualmetne un pedido combinado.
  openCombinarPedidoModal(
    ordenProgramadaInstance: OrdenProgramadaComponent,
    matchOrder: any
  ) {
    // creamos el modal de crear pedido combinado si no existe y lo desplegamos.
    if (this.componentRefCombinarPedido == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalNuevoPedidoCombinadoComponent
        );
      this.componentRefCombinarPedido =
        this.containerCombinarPedido.createComponent(componentFactory);
    }
    var response =
      this.componentRefCombinarPedido.instance.generatePlantClientTimes(
        ordenProgramadaInstance,
        matchOrder,
        this.timePlantClient,
        this.plantData.name,
        this
      );
    if (response) {
      this.componentRefCombinarPedido.instance.show();
    }
    return response;
  }

  async openCombinarPedidoModalNoProgrammed(
    ordenNoProgramadaInstance: OrdenNoProgramadaComponent,
    matchOrder: any
  ) {
    this.SpinnerService.show();
    if (this.componentRefCombinarPedido == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalNuevoPedidoCombinadoComponent
        );
      this.componentRefCombinarPedido =
        this.containerCombinarPedido.createComponent(componentFactory);
    }
    var response =
      await this.componentRefCombinarPedido.instance.generatePlantClientTimesFromNoProgrammedOrder(
        ordenNoProgramadaInstance,
        matchOrder,
        this.timePlantClient,
        this.plantData.name,
        this
      );

    if (response) {
      this.componentRefCombinarPedido.instance.show();
    }
    this.SpinnerService.hide();
    return response;
  }

  refreshPlannedOrderOnGrid() {
    // CLEAR ALL IN GRID
    this.minuteCells.forEach((mc) => {
      mc.removePlannedOrder();
    });
    this.setPlannedOrderOnGrid();
  }

  private setPlannedOrderOnGrid() {
    this.overlapped_dict = {};
    this.pedidos.forEach((p) => {
      if (p.VEHICLE != " " && p.AGRUPADOR.trim() == "") {
        let match_overlapped = this.pedidos.filter(
          (e) =>
            e.PRO_BEGTI == p.PRO_BEGTI &&
            e.PRO_BEGDA == p.PRO_BEGDA &&
            e.VEHICLE == p.VEHICLE
        );
        if (match_overlapped.length > 1) {
          if (!(p.VBELN in this.overlapped_dict)) {
            let temp_time = p.PRO_BEGTI.substring(2, 4);
            if (temp_time === "30") {
              let new_time =
                p.PRO_BEGTI.substring(0, 2) +
                "00" +
                p.PRO_BEGTI.substring(4, 6);
              p.PRO_BEGTI = new_time;
            } else {
              let new_time =
                p.PRO_BEGTI.substring(0, 2) +
                "30" +
                p.PRO_BEGTI.substring(4, 6);
              p.PRO_BEGTI = new_time;
            }

            match_overlapped.forEach((e) => {
              this.overlapped_dict[e.VBELN] = match_overlapped;
            });
          }
        }
      }

      if (!this.programadorFunctions.esPedidoNoProgramado(p)) {
        // p.ESTADO!="1"){//estado 1 entendemos que es no programado
        let truckId = p.VEHICLE;
        // dd-mm-yyyy
        let date = this.utils.yyyymmddToDate(p.PRO_BEGDA); // PRO_BEGDA tiene el date de inicio de la programacion
        // integer with the hour
        let startHour = this.utils.hhmmmmToHour(p.PRO_BEGTI);
        // we must ensure to use the resolution of the grid
        let startMinute = this.utils.hhmmmmToMinute(p.PRO_BEGTI) < 30 ? 0 : 30;
        if (date === this.utils.yesterday(this.plantData.date)) {
          // SETEAMOS QUE LA ORDEN PARTA A LAS 14 horas (hora predeterminada desde cuando parte el dia de ayer)
          // Sin embargo, el largo de la caja que representa el pedido, se trabaja en la misma orden programada component,
          // que detecta si el pedido es de ayer y solo se debe mostrar una parte de el (desde las 14 horas), se recorta.
          if (
            this.utils.hhmmmmToHour(p.PRO_BEGTI) < this.gridData[0].hourFrom
          ) {
            startHour = this.gridData[0].hourFrom;
            startMinute = 0;
          }
        }

        let cellToRender = this.minuteCells.filter(
          (e) =>
            e.truck == truckId &&
            e.hour == startHour &&
            e.minute == startMinute &&
            e.date == date
        )[0];

        this.activarClickOrden();
        if (cellToRender !== undefined) {
          // cponce: Para que alguien no sufra en el futuro a la hora de entender esto
          cellToRender.attachPlannedOrder(p);
        } else {
          // En caso de no encontrar la celda, vamos a esperar hasta que este lista
          const intervalMs = 500; // ms
          const maxReintentos = 20;
          let reintentos = 1;
          const intervalId = setInterval(() => {
            const foundCell = this.minuteCells.filter(
              (cell) =>
                cell.truck == truckId &&
                cell.hour == startHour &&
                cell.minute == startMinute &&
                cell.date == date
            )[0];
            if (
              foundCell !== undefined &&
              this.trucksLoaded &&
              this.pedidosLoaded
            ) {
              this.activarClickOrden();
              foundCell.attachPlannedOrder(p);
              clearInterval(intervalId);
            } else if (this.trucksLoaded && this.pedidosLoaded) {
              reintentos += 1;
              if (reintentos >= maxReintentos) {
                clearInterval(intervalId);
              }
            }
          }, intervalMs);
        }
      }
    });
  }

  // 2* because per hour ther are 2 cells (30 minutes)
  generateGridData() {
    const yesterday_from: number = 14;
    this.gridData = [
      {
        hourFrom: yesterday_from,
        hourTo: 23,
        hours: this.utils.generateHourArray(yesterday_from, 23),
        displayDate: this.utils.yesterday(this.plantData.date),
        // class: "day-column-notoday",
        width: 2 * this.hoursYesterday * this.pixelUnitTime,
      },
      {
        hourFrom: 0,
        hourTo: 23,
        hours: this.utils.generateHourArray(0, 23),
        displayDate: this.plantData.date,
        // class: "day-column-today",
        width: 2 * this.hoursDay * this.pixelUnitTime,
      },
      {
        hourFrom: 0,
        hourTo: 9,
        hours: this.utils.generateHourArray(0, 9),
        displayDate: this.utils.tomorrow(this.plantData.date),
        // class: "day-column-notoday",
        width: 2 * this.hoursTomorrow * this.pixelUnitTime,
      },
    ];
    this.refresh();
  }

  removeOrdersFromPlanning(orders) {
    orders.forEach((ord) => {
      ord.VEHICLE = " ";
      ord.AGRUPADOR = " ";
      ord.POS_AGRUPADOR = "0";
    });
    this.refreshPlannedOrderOnGrid();
  }

  // funciones temporales que despues no voy a usar
  // permite que se active flag para aceptar agregar orden a grilla
  activarClickOrden() {
    this.gridReadyToClick = true;
  }

  refresh() {
    this.ref.detectChanges();
  }

  // ante algun cambio, combiene usar esta funcion que entre otras cosas, si hay una baja de turno, debe eliminarlo.
  updateGrid() {
    this.SpinnerService.show();
    // Dado que se bajo un turno, hay que recalcular la disponibilidad de las celdas. Para ello se
    // limpian todas las celdas (se dejan como no disponibles), y luego se recalcula la disponibilidad
    // deja todas las celdas como  no disponibles
    this.clearCellAvailability();
    // define las celdas que estaran disponibles para los turnos de los camiones
    this.minuteCells.forEach((elm) => {
      elm.checkCellTruckAvailability();
    });
    // los elementos que estaban sobre las celdas otrora disponibles, se eleiminan.
    this.checkPlannedCellAvailability();
    this.updateCountPedidos();
    this.SpinnerService.hide();
  }

  clearHighLight() {
    this.clearShadow();
  }

  clearShadow() {
    if (this.shadowUL != undefined) {
      this.shadowUL.instance.hide();
    }
    if (this.shadowUR != undefined) {
      this.shadowUR.instance.hide();
    }

    if (this.shadowDL != undefined) {
      this.shadowDL.instance.hide();
    }
    if (this.shadowDR != undefined) {
      this.shadowDR.instance.hide();
    }
  }

  highLightCells(coord_obj: any) {
    this.updateShadowHighLight(coord_obj);
  }

  darBaja(order, comentariosDarBaja, motivoBaja) {
    var IndexOrdersDelete = [];
    var startingIndexOrder = -1;
    this.minuteCells.forEach((mc, index) => {
      if (
        mc.truck == order.VEHICLE &&
        mc.hour == this.utils.hhmmmmToHour(order.PRO_BEGTI) &&
        mc.minute ==
          this.utils.adjustMinuteToCellResolution(
            this.utils.hhmmmmToMinute(order.PRO_BEGTI)
          ) &&
        mc.date == this.utils.yyyymmddToDate(order.PRO_BEGDA)
      ) {
        // No limpio los pedidos que no se pueden dar de baja
        this.trucks.forEach((truck) => {
          if (mc.truck == truck.truckId) {
            // si damos de baja en turnos que son del mismo dia
            // LA CELDA ES DEL DIA DE HOY
            if (
              this.utils.dateToTimestamp(mc.date) ==
              this.utils.dateToTimestamp(this.plantData.date)
            ) {
              // la celda de hoy parte de un turno que parte hoy. En ese caso hay que identificar si esta en uno de los dos turnos del dia

              if (mc.StartDate != undefined) {
                if (
                  this.utils.dateToTimestamp(mc.date) ==
                  this.utils.dateToTimestamp(mc.startDate)
                ) {
                  if (
                    this.programadorFunctions.getShiftT1(truck.shifts) != -1 ||
                    this.programadorFunctions.getShiftT2(truck.shifts) != -1
                  ) {
                    startingIndexOrder = index;
                  }
                } else if (
                  this.utils.dateToTimestamp(mc.date) >
                  this.utils.dateToTimestamp(mc.startDate)
                ) {
                  startingIndexOrder = index;
                }
              } else if (mc.StartDate == undefined) {
                startingIndexOrder = index;
              }
            } else if (
              this.utils.dateToTimestamp(mc.date) <
              this.utils.dateToTimestamp(this.plantData.date)
            ) {
              // si la celda es del dia de ayer, bajamos si se pide
              startingIndexOrder = index;
            } else if (
              this.utils.dateToTimestamp(mc.date) >
              this.utils.dateToTimestamp(this.plantData.date)
            ) {
              // si la celda es del dia de ayer, bajamos si se pide
              startingIndexOrder = index;
            }

            // si damos de baja en turnos que son de un turno que viene de otro dia anterior
          }
        });
      }
    });

    // siempre es un elemento. Es el pedido del detalle el que queremos dar de baja.
    if (startingIndexOrder != -1) {
      IndexOrdersDelete.push(startingIndexOrder);
    }

    IndexOrdersDelete.forEach((index) => {
      let orderToRemove = this.pedidos.filter(
        (po) =>
          po.VEHICLE == this.minuteCells[index].truck &&
          this.utils.yyyymmddToDate(po.PRO_BEGDA) ==
            this.minuteCells[index].date &&
          this.utils.hhmmmmToHour(po.PRO_BEGTI) ==
            parseInt(this.minuteCells[index].hour, 10) &&
          this.utils.adjustMinuteToCellResolution(
            this.utils.hhmmmmToMinute(po.PRO_BEGTI)
          ) == parseInt(this.minuteCells[index].minute, 10)
      )[0];

      if (orderToRemove.AGRUPADOR == undefined) {
      }
      // una vez determinada la orden a remover, vemos si tiene un agrupador.
      // En caso de tener, damos de baja en cascada todos los pedidos combinados asociados.
      if (
        orderToRemove.AGRUPADOR != undefined &&
        orderToRemove.AGRUPADOR.trim() != ""
      ) {
        var pedidosAgrupadosDarBaja = this.pedidos.filter(
          (e) => e.AGRUPADOR == orderToRemove.AGRUPADOR
        );
        pedidosAgrupadosDarBaja.forEach((e) => {
          e.AGRUPADOR = " ";
          e.POS_AGRUPADOR = "0";
          // e.DURACION_GRUPO = " "
          e.VEHICLE = " ";
          e.UNSUBSCRIBE_COMMENT = comentariosDarBaja;
          e.UNSUBSCRIBE_CODE = motivoBaja;
        });
      } else {
        // si es un pedido no combinaod
        // orderToRemove.ESTADO="1"
        orderToRemove.AGRUPADOR = " ";
        orderToRemove.POS_AGRUPADOR = "0";
        // orderToRemove.DURACION_GRUPO=" "
        orderToRemove.VEHICLE = " ";
        // orderToRemove.CONFIRMADO="N"
        orderToRemove.UNSUBSCRIBE_COMMENT = comentariosDarBaja;
        orderToRemove.UNSUBSCRIBE_CODE = motivoBaja;
      }
      this.minuteCells[index].removeProgrammedOrder();
    });
    this.updateCountPedidos();
  }

  deconfirm(
    pedidoDesconfirmar: any,
    changeTruckData: any = { use: false },
    automatic: boolean = false
  ) {
    this.unconfirmProcess = true;

    this.timestamp = Math.floor(Date.now() / 1000);
    this.confirmDeconfirmFlag = true;

    let desconfirmarIds = [];
    pedidoDesconfirmar.forEach((p) => {
      desconfirmarIds.push(p.VBELN);
    });

    let other_peds = this.pedidos.filter(
      (p) => !desconfirmarIds.includes(p.VBELN)
    );

    this.save(other_peds);

    this.SpinnerService.show();
    const dataRequest: UnconfirmDataRequest = {
      plan: pedidoDesconfirmar,
      timestamp: this.Version, //this.timestamp,
      fecha: this.utils.dateToSapformat(this.plantData.date),
      codigo_planta: this.plantData.code,
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
      automatic_request: automatic,
      version: this.timestampGuardar, //this.VersionAux === 0 ? this.Version : this.VersionAux,
    };
    // this.timestamp = -1
    this.confirmDeconfirmFlag = false;

    // let response = this.api.desconfirmar(pedidoDesconfirmar,this.loginData.bearer);
    let response = this.api.desconfirmar(dataRequest);
    response.subscribe(
      (data: { retCode: any; message: any }) => {
        const startAsync = async (callback) => {
          await wait(1000);
          callback();
        };
        startAsync((text) => {
          this.ERc = data["ERc"];
          this.EMessage = data["EMessage"];
          this.showSapMessages();

          if (this.ERc == 0) {
            if (parseInt(data.retCode, 10) == 200) {
              this.openAlert("success", this.EMessage); // pedidos descofirmados

              data["lista_desconfirmados"].forEach((idPed) => {
                var p = this.pedidos.filter((ped) => ped.VBELN == idPed);
                if (p.length == 1) {
                  p[0].ESTADO = "1";
                  p[0].AGRUPADOR = " ";
                  p[0].POS_AGRUPADOR = "0";
                  this.darBaja(p[0], "", "");
                }

                if (idPed in this.overlapped_dict) {
                  p.overlapped = false;
                  p.ERRORS = " ";
                  p.ERROR_MESSAGE = " ";
                  this.overlapped_dict[idPed].forEach((e) => {
                    e.overlapped = false;
                    e.ERRORS = " ";
                    e.ERROR_MESSAGE = " ";
                    delete this.overlapped_dict[e.VBELN];
                  });
                  delete this.overlapped_dict[p.VBELN];
                }
              });
              if (changeTruckData.use) {
                this.confirmCambioTurno(changeTruckData);
              }
            } else {
              this.unconfirmProcess = false;
              this.SpinnerService.hide();
              this.openAlert(
                "danger",
                "Ha ocurrido un error al desconfirmar el pedido solicitado. Intente nuevamente"
              );
            }
          } else {
            this.unconfirmProcess = false;
            this.SpinnerService.hide();
          }
          if (changeTruckData.use == false) {
            this.unconfirmProcess = false;
            this.SpinnerService.hide();
          }
          this.updateCountPedidos();
        });
      },
      (err) => {
        this.unconfirmProcess = false;
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  // la confirmacion tiene que ir asociada a un servicio
  confirmSelected() {
    this.confirm(true);
  }

  confirmCambioTurno(cambioTurnoData) {
    var deconfirm_truck_data = cambioTurnoData.truckData;
    var darAlta = cambioTurnoData.darAlta;
    var dataConfirm: ConfirmChangeTurnDataRequest = {
      plan: deconfirm_truck_data,
      codigo_planta: this.plantData.code,
      fecha: this.utils.dateToSapformat(this.plantData.date),
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
    };
    this.SpinnerService.show();
    let response = this.api.confirmarCambioTurno(dataConfirm);
    response.subscribe(
      (data: { retCode: any; message: any }) => {
        const startAsync = async (callback) => {
          await wait(1000);
          callback();
        };
        startAsync((text) => {
          this.ERc = data["ERc"];
          this.EMessage = data["EMessage"];
          this.showSapMessages();

          if (this.ERc == 0) {
            if (parseInt(data.retCode, 10) == 200) {
              this.openAlert("success", data.message); // "Los pedidos han sido confirmados.")
              if (darAlta) {
                cambioTurnoData.shift.active = true;
                // eliminamos el camion de la lista de camiones con turno abajo
                var newVD = [];
                this.vehicleDown.forEach((vdr) => {
                  if (
                    !(
                      vdr.TurnoFechaDesde ==
                        deconfirm_truck_data.TurnoFechaDesde &&
                      vdr.TurnoFechaHasta ==
                        deconfirm_truck_data.TurnoFechaHasta &&
                      vdr.TurnoHoraDesde ==
                        deconfirm_truck_data.TurnoHoraDesde &&
                      vdr.TurnoHoraHasta ==
                        deconfirm_truck_data.TurnoHoraHasta &&
                      vdr.Vehicle == deconfirm_truck_data.Vehicle
                    )
                  ) {
                    newVD.push(vdr);
                  }
                });
                this.vehicleDown = newVD;
              } else {
                // si damos de abaja

                cambioTurnoData.shift.active = false;
                // agragamos a vehicleDown el turno
                var vd: any;
                vd.TurnoFechaDesde = deconfirm_truck_data.TurnoFechaDesde;
                vd.TurnoFechaHasta = deconfirm_truck_data.TurnoFechaHasta;
                vd.TurnoHoraDesde = deconfirm_truck_data.TurnoHoraDesde;
                vd.TurnoHoraHasta = deconfirm_truck_data.TurnoHoraHasta;
                vd.Vehicle = deconfirm_truck_data.Vehicle;
                this.vehicleDown.push(vd);
              }

              this.updateGrid();
              // todos los pedidos que coinciden con la lista verificada de ID de pedidos confirmados, le cambiamos el estado a 3.
              // Recordar que el Servicio chequea y guarda a dynamo por lo que no hay que hacer nada mas que cambiar el estado para mostrar.
            } else {
              // this.openAlert("danger","Ha ocurrido un error al confirmar los pedidos solicitados. Intente nuevamente")
            }
          } else {
            this.SpinnerService.hide();
            this.openAlert(
              "danger",
              "Ha ocurrido un error al confirmar los pedidos solicitados. Intente nuevamente"
            );
          }

          this.updateCountPedidos();
        });
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  getPedidosNoWebSelected() {
    return this.pedidos.filter((p) => p.WEB_SELECTED == undefined);
  }

  /// NEW CONFIRM
  confirm(seleccion = false) {
    if (!this.sessionEditPermission) {
      return;
    }
    this.timestamp = Math.floor(Date.now() / 1000);
    this.confirmDeconfirmFlag = true;
    var pedidosNoSeleccionados = this.getPedidosNoWebSelected();
    this.readySaveBeforeConfirm = false;
    this.confirmEnded = false;
    if (pedidosNoSeleccionados.length > 0) {
      const dataSave = {
        use: true,
        dataPedidos: pedidosNoSeleccionados,
      };
      this.save(dataSave);
    } else {
      // we dont save, so we set this true in order to continue
      this.readySaveBeforeConfirm = true;
    }
    this.waitSaveBeforeConfirm(seleccion);
  }

  // wait until readySaveBeforeConfirm is true (save process finish)
  waitSaveBeforeConfirm(seleccion = false) {
    this.SpinnerService.show();

    const startAsync = async (callback) => {
      await wait(3000);
      if (!this.readySaveBeforeConfirm) {
        this.waitSaveBeforeConfirm(seleccion);
      } else {
        await this.callConfirmAfterSave(seleccion);
        this.seleccionPedidos = false;
      }
    };
    startAsync((text) => {});
  }

  async callConfirmAfterSave(seleccion = false) {
    // buscamos los pedidos que esten en la grilla y desbloqueados (CMGST no B). Verificamos ademoas que
    // tengan vehiculo asociado. Puede venir un dato de pedido estado 3, no bloqueado pero sin camion.
    // var pedidosProgramados = this.pedidos.filter(e=>e.ESTADO=="3" && e.CMGST!="B" && e.VEHICLE.trim()!="");

    var pedidosBloqueados = [];
    var pedidosAConfirmar = [];

    this.pedidos.forEach((e, index) => {
      // agregamos los pedidos que tengan error message. Estos pedidos no pueden dejar confirmar
      if (
        e.ERROR_MESSAGE.trim() != "" &&
        e.ESTADO != "3" &&
        e.VEHICLE.trim() != ""
      ) {
        var info = { VBELN: e.VBELN, ERROR_MESSAGE: e.ERROR_MESSAGE };
        pedidosBloqueados.push(info);
      }

      if (e.overlapped == true) {
        var info = { VBELN: e.VBELN, ERROR_MESSAGE: e.ERROR_MESSAGE };
        pedidosBloqueados.push(info);
        var message =
          "ERROR: El pedido " +
          e.VBELN +
          " esta superpuesto, Bajar/Desconfirmar.";
        this.openAlert("danger", message);
        e.ERRORS = "S";
        e.ERROR_MESSAGE = message;
      }
      if (
        e.TIENE_RUTA != undefined &&
        e.TIENE_RUTA == false &&
        e.VEHICLE.trim() != ""
      ) {
        var info = { VBELN: e.VBELN, ERROR_MESSAGE: e.ERROR_MESSAGE };
        pedidosBloqueados.push(info);
        var message = "El pedido " + e.VBELN + " no tiene ruta.";
        this.openAlert("danger", message);
        e.ERRORS = "S";
        e.ERROR_MESSAGE = message;
      }

      // añadir condición de

      var condition = this.programadorFunctions.esPedidoProgramado(e); // nos saltamos la condicion de block
      if (seleccion) {
        condition = condition && e.WEB_SELECTED != undefined;
      }

      if (condition) {
        var p = {};
        Object.assign(p, e);
        // En el servicio sap, si no le damos un agrupador o le damos el mismo agrupador,
        // los asigna como un combinado. Por eso para confirmar,
        // es necesario darle un numero diferente a los pedidos a confirmar.
        // Por eso usamos el index, asegurandonos que no se repita el agrupador.
        if (p["AGRUPADOR"].trim() == "") {
          pedidosAConfirmar.push(p);
        }

        // de los pedidos no bloqueados, que tienen agrupador, vemos si hay otro pedido con el mismo agrupador que SI este bloqueado.
        // Con esto evitamos confirmar pedidos combinados con alguno de sus pedidos que no se pueda confirmar

        if (p["AGRUPADOR"].trim() != "") {
          var agrupadosToCheck = this.pedidos.filter(
            (ped) => ped.AGRUPADOR == p["AGRUPADOR"]
          );
          var agrupadosHasBlocker = agrupadosToCheck.filter(
            (ped) => ped.CANNOT_CONFIRM == "S"
          );
          // si no hay pedidos con el agrupador que se encuentren bloqueados
          if (agrupadosHasBlocker.length == 0) {
            // si el pedido no tiene pedidos bloqueados asociados, lo agrego
            if (seleccion) {
              agrupadosToCheck.forEach((agt) => {
                pedidosAConfirmar.push(agt);
              });
            } else {
              pedidosAConfirmar.push(p);
            }
          } else {
            agrupadosHasBlocker.forEach((elm) => {});
          }
        }
      }
    });

    if (pedidosBloqueados.length != 0) {
      this.SpinnerService.hide();
      this.openAlert(
        "danger",
        "Existen pedidos que no permiten realizar la operación de confirmar (Ver pedidos en rojo)."
      );
      return;
    }

    if (pedidosAConfirmar.length == 0) {
      // avisar y avisar por si hay bloqueo
      this.openAlert("warning", "No hay pedidos que se puedan confirmar.");
      this.SpinnerService.hide();
      return;
    }

    pedidosAConfirmar.forEach((e, index) => {
      if (e.AGRUPADOR.trim() == "") {
        e.AGRUPADOR = index;
      }
    });

    await this.addConfirmDetails(pedidosAConfirmar);
    var dataConfirm: PostPlanRequest = {
      plan: pedidosAConfirmar,
      timestamp: this.timestamp,
      codigo_planta: this.plantData.code,
      fecha: this.utils.dateToSapformat(this.plantData.date),
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
      version: this.Version,
      listado: [],
    };
    dataConfirm.listado = this.programadorFunctions.addDistrList();
    this.timestamp = -1;
    this.confirmDeconfirmFlag = false;
    this.spinnerText = PROGRAMADOR_CONST.SPINNER_TEXT_WAIT_ORDERS_CONFIRMATION;
    this.SpinnerService.show();
    let response = this.api.confirmar(dataConfirm);

    response.subscribe(
      (data: { retCode: any; message: any }) => {
        const startAsync = async (callback) => {
          await wait(1000);
          callback();
        };
        startAsync((text) => {
          this.ERc = data["ERc"];
          this.EMessage = data["EMessage"];
          this.showSapMessages();
          if (this.ERc == 0) {
            if (parseInt(data.retCode, 10) == 200) {
              this.openAlert("success", this.EMessage); // "Los pedidos han sido confirmados.")
              this.spinnerText = PROGRAMADOR_CONST.SPINNER_TEXT_SUCCESS_SAP;
              this.SpinnerService.show();

              /*
					 //todos los pedidos que coinciden con la lista verificada de ID de pedidos confirmados, le cambiamos el estado a 3.
					 //Recordar que el Servicio chequea y guarda a dynamo por lo que no hay que hacer nada mas que cambiar el estado para mostrar.
					 */
            } else {
              this.openAlert(
                "danger",
                "Ha ocurrido un error al confirmar los pedidos solicitados. Intente nuevamente"
              );
              this.openAlert("danger", this.EMessage); // "Los pedidos han sido confirmados.")
            }
          } else {
          }
          // reload plan, so if there were issues from sap when confirm, updating we will see what was real confirmed.
          this.update();
        });
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  /**
   * Setea a 0 el atributo POS_AGRUPADOR para pedidos con campo AGRUPADOR vacío
   * @returns void
   */
  cleanPosAgrupadorIfNotAgrupador() {
    this.pedidos.forEach((pedido) => {
      if (pedido.AGRUPADOR.trim() == "") {
        pedido.POS_AGRUPADOR = "0";
      }
    });
  }

  async addConfirmDetails(pedidosAConfirmar: any) {
    let haciaCliente = [];
    let pedidosGuardados: boolean = false;
    const fPedidosConfirmar = async () => {
      const plantaCliente = [];

      for (let pedido of pedidosAConfirmar) {
        if (
          pedido.AGRUPADOR != "" &&
          typeof pedido.AGRUPADOR != "number" &&
          !plantaCliente.includes(pedido.AGRUPADOR)
        ) {
          //ingresamos todos los pedidos  al arreglo
          plantaCliente.push(pedido.KUNAG);
        }
      }

      //dejamos solo valores únicos en el arreglo
      let parsedClients = _.uniq(plantaCliente);

      for (let cliente of parsedClients) {
        //con pedidos únicos, solo llamamos la servicio una vez por pedidos, evitando llamadas duplicadas
        const tpos = await this.api.getTimesForSchedule(
          this.plantData.code,
          cliente * 1,
          parseInt(this.plantData.code)
        );

        tpos["body"]["origen"] = parseInt(this.plantData.code);
        tpos["body"]["destino"] = parseInt(cliente);
        haciaCliente.push(tpos["body"]);
      }
      let entreCliente = [];
      for await (let pedido of pedidosAConfirmar) {
        if (pedido.DURACION_GRUPO.trim() == "") {
          // si viene vacio (no debiera), completamos con 000000
          pedido.DURACION_GRUPO = "000000";
        }

        // si hay agrupador, necesitamos ver los pedidos por su orden secuencial y obtener los pedidos entre cliente
        // Si el pedido es p1 , le damos la distancia entre p1 y p2
        if (pedido.AGRUPADOR != "" && typeof pedido.AGRUPADOR != "number") {
          pedido.TPO_CARGA = this.utils.millisecondsToHHMMSS(
            this.utils.minutesToMilliseconds(
              haciaCliente.filter((e) => e.destino == parseInt(pedido.KUNAG))[0]
                .tiempo_carga_default
            )
          );
          pedido.TPO_DESCA = this.utils.millisecondsToHHMMSS(
            this.utils.minutesToMilliseconds(
              haciaCliente.filter((e) => e.destino == parseInt(pedido.KUNAG))[0]
                .tiempo_descarga_default
            )
          );
          pedido.TPO_COMBI = this.utils.millisecondsToHHMMSS(
            this.utils.minutesToMilliseconds(0)
          );

          var agrupador = parseInt(pedido.AGRUPADOR, 10);
          var pedidosAgrupados = pedidosAConfirmar.filter(
            (p) => parseInt(p.AGRUPADOR, 10) == agrupador
          );
          // recalculamos algunos valores por si venian de un pedido previamente guardado
          // this.utils.calcularPedidoCombinado(pedidosAgrupados, this);

          // se verifica que sea un pedido agrupado guardado, comprobando que no tenga esos atributos; si no los tiene la variable toma valor true
          pedidosAgrupados.forEach((pa) => {
            if (
              !pa.hasOwnProperty("CONFIR_START_DATE") ||
              !pa.hasOwnProperty("CONFIR_START_TIME")
            ) {
              pedidosGuardados = true;
            }
          });
          // con valor true, se llama la funcion para calcular los startime de los pedidos guardados
          if (pedidosGuardados) {
            this.utils.calcularTiemposPedidosGuardados(pedidosAgrupados);
          }
          // pueden venir pedidos singles con agrupador....
          if (
            pedidosAgrupados.length >= 2 &&
            !entreCliente.includes(pedidosAgrupados[0].AGRUPADOR)
          ) {
            pedidosAgrupados.sort(this.utils.comparePedidoByPosAgrupador);
            entreCliente.push(pedidosAgrupados[0].AGRUPADOR);
            let dataFmin;
            let mirrorD;
            const getMinutes = async (from, to) => {
              let resp = await this.api.getTimesForSchedule(
                from,
                to,
                parseInt(this.plantData.code)
              );
              return resp;
            };
            var minutes;
            for (var i = 0; i < pedidosAgrupados.length; i++) {
              var kunag_from = 0; // parseInt(pedidosAgrupados[i]["KUNAG"])
              var kunag_to = 0;
              if (i == 0) {
                var pedido_from = pedidosAgrupados[i];
                var pedido_to = pedidosAgrupados[i + 1];
                kunag_from = parseInt(pedido_from.KUNAG, 10);
                kunag_to = parseInt(pedido_to.KUNAG, 10);
                mirrorD = await getMinutes(kunag_from, kunag_to);
                dataFmin = mirrorD;
              } else {
                var pedido_from = pedidosAgrupados[i - 1];
                var pedido_to = pedidosAgrupados[i];
                kunag_from = parseInt(pedido_from.KUNAG, 10);
                kunag_to = parseInt(pedido_to.KUNAG, 10);
                dataFmin = mirrorD;
              }
              // var minutes = this.matrizDuraciones[kunag_from].filter(e => e['destination'] == kunag_to)[0]['minutes'];
              //  const dataFmin = await getMinutes()
              minutes = dataFmin["body"].minutes;

              pedidosAgrupados[i]["TPO_COMBI"] =
                this.utils.millisecondsToHHMMSS(
                  this.utils.minutesToMilliseconds(minutes)
                );
            }
          }
        } else {
          // si tenemos un pedido cuyo agrupador es vacio...
          pedido["CONFIR_START_DATE"] = pedido["PRO_BEGDA"];
          pedido["CONFIR_START_TIME"] = pedido["PRO_BEGTI"];

          pedido.TPO_CARGA = this.utils.millisecondsToHHMMSS(
            this.utils.minutesToMilliseconds(this.tpoCarga)
          );
          pedido.TPO_DESCA = this.utils.millisecondsToHHMMSS(
            this.utils.minutesToMilliseconds(this.tpoDesca)
          );
          pedido.TPO_COMBI = this.utils.millisecondsToHHMMSS(
            this.utils.minutesToMilliseconds(0)
          );
        }
      }
    };

    await fPedidosConfirmar();
    var listaAgrupadores = {};
    pedidosAConfirmar.forEach((ped) => {
      if (listaAgrupadores[parseInt(ped["AGRUPADOR"], 10)] == undefined) {
        listaAgrupadores[parseInt(ped["AGRUPADOR"], 10)] = [];
      }
      listaAgrupadores[parseInt(ped["AGRUPADOR"], 10)].push({
        POS_AGRUPADOR: ped["POS_AGRUPADOR"],
        VBELN: ped["VBELN"],
      });
    });

    const fListaAgrupadores = async () => {
      for await (let e of Object.entries(listaAgrupadores)) {
        var items: any;
        items = e[1];
        items.sort(this.utils.comparePedidoByPosAgrupador);
        for (let [idx, it] of items.entries()) {
          var vbeln = it["VBELN"];
          var modify = pedidosAConfirmar.filter(
            (pag) => pag["VBELN"] == vbeln
          )[0];
          modify["RUTA"] = modify["DURACION"];

          if (idx == items.length - 1 && items.length > 1) {
            var kunagUltimoPedido = parseInt(modify["KUNAG"], 10);
            var codigoPlanta = parseInt(this.plantData.code, 10);
            // modify['RUTA'] = this.matrizDuraciones[kunagUltimoPedido].filter(ee => ee['destination'] == codigoPlanta)[0]['duracion'];
            const dataRuta = await this.api.getTimesForSchedule(
              kunagUltimoPedido,
              codigoPlanta,
              parseInt(this.plantData.code)
            );
            modify["RUTA"] = dataRuta["body"].duracion;
          }
        }
      }
    };
    await fListaAgrupadores();
  }

  clearCellAvailability() {
    // update the model
    this.minuteCells.forEach((mc) => {
      mc.setNoAvailable();
    });
  }

  checkPlannedCellAvailability() {
    var IndexOrdersDelete = [];
    let programmedOrders =
      this.truckIdModalShiftSwitch != ""
        ? this.pedidos.filter(
            (e) =>
              !this.programadorFunctions.esPedidoNoProgramado(e) &&
              e.VEHICLE == this.truckIdModalShiftSwitch
          )
        : this.pedidos.filter(
            (e) => !this.programadorFunctions.esPedidoNoProgramado(e)
          ); // 3 confirmado, 1 programado o no. Depende del vehiculo
    // para cada pedido programado ....
    programmedOrders.forEach((po) => {
      // ...Obtengo el indice del minuteCell donde parte el pedido.
      var startingIndexOrder = -1;

      this.minuteCells.forEach((mc, index) => {
        if (
          mc.truck == po.VEHICLE &&
          mc.hour == this.utils.hhmmmmToHour(po.PRO_BEGTI) &&
          mc.minute ==
            this.utils.adjustMinuteToCellResolution(
              this.utils.hhmmmmToMinute(po.PRO_BEGTI)
            ) &&
          mc.date == this.utils.yyyymmddToDate(po.PRO_BEGDA)
        ) {
          startingIndexOrder = index;
        }
      });

      // if starting index es -1, es porque puede haber una orden agendada y un camion que no esta en la lista. Esa orden no se despliega en la grilla, NI QUEDA COMO NO PROGRAMADA. Es de la BD tal cual
      if (startingIndexOrder != -1) {
        // SUMO los tiempos dados por plantClientTimes
        var totalMinutes = 0;
        // var duracion = po.DURACION_GRUPO*1 != 0 ? po.DURACION_GRUPO : po.DURACION;
        // var duracion = po.AGRUPADOR.trim()!="" ? po.DURACION_GRUPO : po.DURACION;
        var duracion =
          po.AGRUPADOR.trim() != "" ? po.DURACION_GRUPO : po.DURACION_CALCULA;
        // duracion = this.utils.hhmmssToHHMMSS(duracion)
        duracion = this.utils.hhmmssSeparateWithColons(duracion);

        totalMinutes += this.utils.hhmmssToMinutes(duracion);

        // divido por la cantidad de resolicion
        // y obtengo la cantidad total de celdas hacia la derecha. Esto me permitira seleccionarlas y determinar
        // si es que alguna de ellas no esta disponible, borrar el pedido.
        let consecutiveCellsInOrder = totalMinutes / this.unitTime;
        // minute index + 4...
        var allCellsAvailable = true;
        for (
          var i = startingIndexOrder;
          i < startingIndexOrder + consecutiveCellsInOrder;
          i++
        ) {
          if (!this.minuteCells[i].available) {
            allCellsAvailable = this.minuteCells[i].available;
          }
        }
        // si encontramos una cell no available para un pedido
        if (!allCellsAvailable) {
          // po["setToDelete"]=true;
          // almaceno el indice de la celda donde parte el pedido a borrar
          IndexOrdersDelete.push(startingIndexOrder);
        }
      }
    });
    // itero sobre el array de indices de cells donde parten los pedidos que voy a borrar.

    IndexOrdersDelete.forEach((index) => {
      // take the element that matchs with the cell and put into non programmed order list

      let orderToRemove = this.pedidos.filter(
        (po) =>
          po.VEHICLE == this.minuteCells[index].truck &&
          this.utils.yyyymmddToDate(po.PRO_BEGDA) ==
            this.minuteCells[index].date &&
          this.utils.hhmmmmToHour(po.PRO_BEGTI) ==
            parseInt(this.minuteCells[index].hour, 10) &&
          this.utils.adjustMinuteToCellResolution(
            this.utils.hhmmmmToMinute(po.PRO_BEGTI)
          ) == parseInt(this.minuteCells[index].minute, 10)
      )[0];
      orderToRemove.AGRUPADOR = " ";
      orderToRemove.POS_AGRUPADOR = "0";
      orderToRemove.VEHICLE = " ";
      this.minuteCells[index].removeProgrammedOrder();
    });
  }

  pushMinuteCell(cellComponent: MinuteCellComponent) {
    if (this.minuteCells == undefined) {
      this.minuteCells = [];
    }
    this.minuteCells.push(cellComponent);
  }

  closeAlert(alert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }

  openAlert(type, message) {
    var newAlert = { type, message };
    this.alerts.push(newAlert);
    setTimeout(() => {
      this.closeAlert(newAlert);
    }, 5000);
  }

  testAlert() {
    this.openAlert("success", "The onee");
  }

  /* This method is use when in UI you click the sort buttons in no programed order grid
	Is also use when dropped a no programed order and fails */
  sortNoProgrammedOrders(colname, sortOrder, indexFromSortButtons) {
    this.indexFromSortButtons = indexFromSortButtons;
    this.pedidos.sort(
      this.compareValues(colname, sortOrder, this.indexFromSortButtons)
    );
    this.sortByEmergencyOrder();
  }

  getTruck(truckId) {
    return this.trucks.filter((e) => e["truckId"] == truckId);
  }


  sortTrucks(sortOrder): void {
    this.SpinnerService.show();
  
    if (this.filtroCamion !== undefined) {
      switch (this.filtroCamion.id) {
        case 1:
          this.sortByCapacityAndFuelTypeAndTruckId(sortOrder);
          break;
        case 2:
          this.sortByFuelTypeAndTruckId(sortOrder);
          break;
        case 3:
          this.sortByTruckId(sortOrder);
          break;
        default:
          this.sortByDefault();
      }
    } else {
      this.sortByDefault();
    }
  
    this.SpinnerService.hide();
  }
  
  private sortByCapacityAndFuelTypeAndTruckId(sortOrder: string): void {
    this.trucks.sort((a, b) => {
      const capacityComparison = this.compareValues("FULL_CAPACITY", sortOrder, -2)(a, b);
      if (capacityComparison !== 0) {
        return capacityComparison;
      }
      const fuelTypeComparison = this.compareValues("fuelTypeCode", sortOrder, -2)(a, b);
      if (fuelTypeComparison !== 0) {
        return fuelTypeComparison;
      }
      return this.compareValues("truckId", sortOrder, -2)(a, b);
    });
  }
  
  private sortByFuelTypeAndTruckId(sortOrder: string): void {
    this.trucks.sort((a, b) => {
      const fuelTypeComparison = this.compareValues("fuelTypeCode", sortOrder, -2)(a, b);
      if (fuelTypeComparison !== 0) {
        return fuelTypeComparison;
      }
      return this.compareValues("truckId", sortOrder, -2)(a, b);
    });
  }
  
  private sortByTruckId(sortOrder: string): void {
    this.trucks.sort(this.compareValues("truckId", sortOrder, -2));
  }
  
  private sortByDefault(): void {
    this.trucks.sort((a, b) => {
      const fuelTypeComparison = this.compareValues("fuelTypeCode", 'asc', -2)(a, b);
      if (fuelTypeComparison !== 0) {
        return fuelTypeComparison;
      }
      const capacityComparison = this.compareValues("FULL_CAPACITY", 'asc', -2)(a, b);
      if (capacityComparison !== 0) {
        return capacityComparison;
      }
      return this.compareValues("truckId", 'asc', -2)(a, b);
    });
  }

  
  sortTrucksByDefault(): void {
    console.log("Ordenando camiones por defecto");
    if (this.trucks && this.trucks.length > 0) {
      this.sortByDefault();
    }
  }
      

  /* Inner method that check firt of all the type of the value that we want to sort and after that, perform the coomparison
	of list values dependong of the selected grid column  */
  compareValues(key, sortOrder, indexFromSortButtons) {
    this.colname = key;
    this.sortOrder = sortOrder;
    this.getSortButtonsStyleClass(indexFromSortButtons);

    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return sortOrder === "desc" ? comparison * -1 : comparison;
    };
  }

  /* This method return a modified class to the ui depending of the selected column grid and order */
  getSortButtonsStyleClass(i) {
    this.returnClassAsc = {};
    this.returnClassDesc = {};
    if (this.sortOrder === "asc") {
      this.returnClassAsc[i] = this.colname + " icon-activated custom-icon-size";
      this.returnClassDesc[i] = this.colname + " custom-icon-size";
    } else {
      this.returnClassAsc[i] = this.colname + " custom-icon-size";
      this.returnClassDesc[i] = this.colname + " icon-activated custom-icon-size";
    }
  }

  /* This method sort no programed orders by client name when we drag and drop a no programed order and fails
	or if we put down the order */
  orderNoprogramedOrdersAsc() {
    if (!this.colname && !this.sortOrder) {
      this.pedidos.sort((a, b) => a.clientName.localeCompare(b.clientName));
    } else {
      this.sortNoProgrammedOrders(
        this.colname,
        this.sortOrder,
        this.indexFromSortButtons
      );
    }
  }

  /*This method allow to keep the colname when we click the search box on the programador grid*/
  onClickSearchBox(colname) {
    this.colname = colname;
    // Limpiamos los valores de los cambios de busqueda si hacemos click en un campo de busqueda.
    // la busqueda solo filtra de a una columna.
    this.searchText = {};
    this.searchText[colname] = "";
  }

  filterNoProgramado(colname) {
    return this.searchText[colname];
  }

  openNoProgramedOrderCommentModal(vbelnOrder: any) {
    if (this.componentRefNoProgramedOrdersComment == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalNoProgramedOrdersCommentsComponent
        );
      this.componentRefNoProgramedOrdersComment =
        this.containerNoProgramedOrdersComments.createComponent(
          componentFactory
        );
    }

    var order = this.pedidos.filter((e) => e.VBELN == vbelnOrder.trim())[0];

    this.componentRefNoProgramedOrdersComment.instance.setComments(
      order["COMENTARIOS"]
    );
    this.componentRefNoProgramedOrdersComment.instance.setClient(
      order["KUNAG_NAME"]
    );
    this.componentRefNoProgramedOrdersComment.instance.setOrderNumber(
      order["VBELN"]
    );

    // seteamos el programador para que pueda hacer uso de sus estructuras
    this.componentRefNoProgramedOrdersComment.instance.setProgramador(this);

    var data = { vbeln: vbelnOrder };
    this.componentRefNoProgramedOrdersComment.instance.show();
  }

  infoModal() {
    if (this.componentRefInfoModal == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          LeyendaModalComponent
        );
      this.componentRefInfoModal =
        this.modalInfoLeyenda.createComponent(componentFactory);
    }
    this.componentRefInfoModal.instance.show();
  }

  largeOrderInfoModal() {
    if (this.componentReflargeOrderModal == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalLargeOrderInfoComponent
        );
      this.componentReflargeOrderModal =
        this.modalLargeOrderInfo.createComponent(componentFactory);
    }

    this.componentReflargeOrderModal.instance.show(this);
  }

  openMontarPedidoModal(
    ordenProgramadaNoProgramadaInstance: any,
    match: any,
    typeOrder: number
  ) {
    if (this.componentRefMontarPedido == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalMontarPedidosComponent
        );
      this.componentRefMontarPedido =
        this.modalMontarPedido.createComponent(componentFactory);
    }
    // seteamos el programador para que pueda hacer uso de sus estructuras
    this.componentRefMontarPedido.instance.setProgramador(this);

    if (typeOrder == 0) {
      this.componentRefMontarPedido.instance.setProgrammedOrder(
        ordenProgramadaNoProgramadaInstance,
        match
      );
      this.componentRefMontarPedido.instance.showModalOrdenProgramada();
    } else {
      this.componentRefMontarPedido.instance.setNotProgrammedOrder(
        ordenProgramadaNoProgramadaInstance,
        match
      );
      this.componentRefMontarPedido.instance.showModalOrdenNoProgramada();
    }
  }

  showChangePlantWarning(data: any) {
    if (this.componentRefShowChangePlantWarningButton == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalCambioPlantafechaComponent
        );
      this.componentRefShowChangePlantWarningButton =
        this.containerCleanButton.createComponent(componentFactory);
    }

    // seteamos el programador para que pueda hacer uso de sus estructuras
    this.componentRefShowChangePlantWarningButton.instance.setProgramador(this);
    this.componentRefShowChangePlantWarningButton.instance.setOperationType(
      data
    );

    this.componentRefShowChangePlantWarningButton.instance.show();
  }

  changeSelectedPlant(plant: any) {
    this.inptuSelectedPlant = plant;
    this.selectedPlant = plant.NAME1;
  }

  changePlantAndDate() {
    const data = {
      plant: this.selectedPlant,
      date: this.modelDate,
    };
    this.showChangePlantWarning(data);
  }

  async okChangePlantAndDate() {
    this.SpinnerService.show();
    await this.modificacionesTurnos.detenerServicio();
    if (this.sessionEditPermission) {
      this.endAdminSession().then((resp) => {});
    }
    let plant = this.inptuSelectedPlant;
    if (plant === undefined) {
      plant = {};
      this.selectedPlant = this.sharedService.getPlantInfo().name;
      plant.NAME1 = this.selectedPlant;
      plant.WERKS = this.sharedService.getPlantInfo().code;
    } else {
      this.selectedPlant = plant.NAME1;
    }
    this.sharedService.setPlantInfo(plant.NAME1, plant.WERKS, this.modelDate);
    this.sessionService.saveLoginData(
      this.sharedService.getPlantInfo(),
      this.sharedService.getLoginInfo(),
      this.sharedService.getPlantList()
    );

    this.sessionService.saveDistrMotives(this.sharedService.getDistrMotives());
    this.sessionService.saveOrdersMotives(
      this.sharedService.getOrdersMotives()
    );

    const newDate = this.utils.modeldateToDate(this.modelDate);
    this.plantData.date = newDate;
    this.plantData.code = plant.WERKS;
    this.plantData.name = plant.NAME1;
    this.update();
    this.manageSession();
    this.checkProposalDate();
    this.timer.updateExpiredTime();
    this.modificacionesTurnos.cambiarPlantaFecha(
      this.plantData,
      this.loginData.user_name
    );
    this.modificacionesTurnos.iniciarServicio();
  }

  OkChangeSelectedPlant() {
    var plant = this.inptuSelectedPlant;
    this.SpinnerService.show();
    this.selectedPlant = plant.NAME1;

    var dateData = {
      year: Number(this.plantData.date.split("-")[2]),
      month: Number(this.plantData.date.split("-")[1]),
      day: Number(this.plantData.date.split("-")[0]),
    };
    this.sharedService.setPlantInfo(plant.NAME1, plant.WERKS, dateData);

    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.PLANT_INFO,
      JSON.stringify(this.sharedService.getPlantInfo())
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.LOGIN_DATA,
      JSON.stringify(this.sharedService.getLoginInfo())
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.PLANT_LIST,
      JSON.stringify(this.sharedService.getPlantList())
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.MOTIVES,
      JSON.stringify(this.sharedService.getDistrMotives())
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.ORDERS_MOTIVES,
      JSON.stringify(this.sharedService.getOrdersMotives())
    );
    this.plantData.code = plant.WERKS;
    // date no change
    this.update();
  }

  rollBackChangeDate() {
    let dia = this.plantData.date.split("-")[0];
    let mes = this.plantData.date.split("-")[1];
    let agno = this.plantData.date.split("-")[2];
    this.modelDate = {
      year: parseInt(agno),
      month: parseInt(mes),
      day: parseInt(dia),
    };
  }

  confirmDate() {
    this.showChangePlantWarning("date");
  }

  OkConfirmDate() {
    this.SpinnerService.show();

    this.sharedService.setPlantInfo(
      this.plantData.name,
      this.plantData.code,
      this.modelDate
    );

    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.PLANT_INFO,
      JSON.stringify(this.sharedService.getPlantInfo())
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.LOGIN_DATA,
      JSON.stringify(this.sharedService.getLoginInfo())
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.PLANT_LIST,
      JSON.stringify(this.sharedService.getPlantList())
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.MOTIVES,
      JSON.stringify(this.sharedService.getDistrMotives())
    );
    sessionStorage.setItem(
      KEYS.SESSION_STORAGE_KEYS.ORDERS_MOTIVES,
      JSON.stringify(this.sharedService.getOrdersMotives())
    );

    var newDate = this.utils.modeldateToDate(this.modelDate);
    this.plantData.date = newDate;
    this.update();
    this.checkProposalDate();
  }

  cleanOrders() {
    this.SpinnerService.show();
    var IndexOrdersDelete = [];

    let programmedOrders = this.pedidos.filter(
      (e) =>
        !this.programadorFunctions.esPedidoNoProgramado(e) &&
        !this.programadorFunctions.esPedidoConfirmado(e)
    );

    programmedOrders.forEach((po) => {
      var startingIndexOrder = -1;

      this.minuteCells.forEach((mc, index) => {
        if (
          mc.truck == po.VEHICLE &&
          mc.hour == this.utils.hhmmmmToHour(po.PRO_BEGTI) &&
          mc.minute ==
            this.utils.adjustMinuteToCellResolution(
              this.utils.hhmmmmToMinute(po.PRO_BEGTI)
            ) &&
          mc.date == this.utils.yyyymmddToDate(po.PRO_BEGDA)
        ) {
          // No limpio los pedidos que no se pueden dar de baja
          this.trucks.forEach((truck) => {
            if (mc.truck == truck.truckId) {
              if (
                this.programadorFunctions.getShiftT1(truck.shifts) != -1 ||
                this.programadorFunctions.getShiftT2(truck.shifts) != -1
              ) {
                startingIndexOrder = index;
              }
            }
          });
        }
      });

      if (startingIndexOrder != -1) {
        IndexOrdersDelete.push(startingIndexOrder);
      }
    });

    IndexOrdersDelete.forEach((index) => {
      let orderToRemove = this.pedidos.filter(
        (po) =>
          po.VEHICLE == this.minuteCells[index].truck &&
          this.utils.yyyymmddToDate(po.PRO_BEGDA) ==
            this.minuteCells[index].date &&
          this.utils.hhmmmmToHour(po.PRO_BEGTI) ==
            parseInt(this.minuteCells[index].hour, 10) &&
          this.utils.adjustMinuteToCellResolution(
            this.utils.hhmmmmToMinute(po.PRO_BEGTI)
          ) == parseInt(this.minuteCells[index].minute, 10)
      )[0];

      orderToRemove.AGRUPADOR = " ";
      orderToRemove.POS_AGRUPADOR = "0";
      orderToRemove.VEHICLE = " ";
      this.minuteCells[index].removeProgrammedOrder();
    });
    this.SpinnerService.hide();
  }

  clickCleanButton(event) {
    event.preventDefault();
    if (!this.sessionEditPermission) {
      return;
    }
    this.openCleanButtonModal();
  }

  openCleanButtonModal() {
    if (this.componentRefCleanButton == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalCleanButtonComponent
        );
      this.componentRefCleanButton =
        this.containerCleanButton.createComponent(componentFactory);
    }

    // seteamos el programador para que pueda hacer uso de sus estructuras
    this.componentRefCleanButton.instance.setProgramador(this);
    this.componentRefCleanButton.instance.show();
  }

  updateShadowHighLight(coord_obj: any) {
    // Los pivot son dos elementos ubicados en el dom como templates que nos permiten ser la base para obtener las coordenadas de los cuadrados negros que se muestran cuando
    // hacemos un highlight cuando se hace el drag de pedidos programados.
    if (this.pivotDL == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          PivotElementComponent
        );
      this.pivotDL = this.pivotDLRef.createComponent(componentFactory);
    }
    this.pivotDL.instance.update(this, "DL");

    if (this.pivotUL == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          PivotElementComponent
        );
      this.pivotUL = this.pivotULRef.createComponent(componentFactory);
    }
    this.pivotUL.instance.update(this, "UL");

    // pasamos la parte del shadow del rectangulo UPPER LEFT
    if (this.shadowUL == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ShadowHighlightComponent
        );
      this.shadowUL = this.shadowHighlightUL.createComponent(componentFactory);
    }

    var paramsUL = {};
    paramsUL["top"] = this.pivotReference["UL"].bottom;
    paramsUL["left"] = this.pivotReference["UL"].left;
    paramsUL["right"] = this.pivotReference["UL"].right;
    paramsUL["bottom"] = this.pivotReference["UL"].bottom;
    paramsUL["width"] =
      parseInt(paramsUL["left"].split("px")[0], 10) +
      coord_obj["UL_dr_x"] +
      "px";
    paramsUL["height"] =
      Math.abs(
        parseInt(paramsUL["bottom"].split("px")[0], 10) - coord_obj["UL_dr_y"]
      ) + "px";
    this.shadowUL.instance.setUL();
    this.shadowUL.instance.updateUL(paramsUL);
    this.shadowUL.instance.show();
    // se debiera hacer un updateUR, update DL y DR

    // pasamos la parte del shadow del rectangule UPPER RIGHT
    if (this.shadowUR == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ShadowHighlightComponent
        );
      this.shadowUR = this.shadowHighlightUR.createComponent(componentFactory);
    }
    var paramsUR = {};
    paramsUR["top"] = paramsUL["top"];
    paramsUR["left"] = coord_obj["UR_dl_x"];
    paramsUR["height"] = paramsUL["height"];

    this.shadowUR.instance.setUR();
    this.shadowUR.instance.updateUR(paramsUR);
    this.shadowUR.instance.show();

    // pasamos la parte del shadow del rectangulo DOWN LEFT
    if (this.shadowDL == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ShadowHighlightComponent
        );
      this.shadowDL = this.shadowHighlightDL.createComponent(componentFactory);
    }

    var paramsDL = {};
    paramsDL["top"] = coord_obj["DL_ul_y"];
    paramsDL["left"] = "0px";
    paramsDL["width"] = paramsUL["width"];

    paramsDL["height"] =
      Math.abs(
        parseInt(this.pivotReference["DL"].top.split("px")[0], 10) -
          parseInt(paramsDL["top"].split("px")[0], 10)
      ) + "px";

    this.shadowDL.instance.setDL();
    this.shadowDL.instance.updateDL(paramsDL);
    this.shadowDL.instance.show();

    // pasamos la parte del shadow del rectangulo DOWN RIGJT
    if (this.shadowDR == undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ShadowHighlightComponent
        );
      this.shadowDR = this.shadowHighlightDR.createComponent(componentFactory);
    }
    var paramsDR = {};
    paramsDR["top"] = paramsDL["top"];
    paramsDR["height"] = paramsDL["height"];
    paramsDR["left"] = paramsUR["left"];

    this.shadowDR.instance.setDR();
    this.shadowDR.instance.updateDR(paramsDR, this.cellMatched);
    this.shadowDR.instance.show();
  }

  async logOut() {
    await this.modificacionesTurnos.detenerServicio();
    this.SpinnerService.show();
    if (this.sessionEditPermission) {
      this.endAdminSession().then((resp) => {});
    }
    this.timer.cleanUp();
    this.sharedService.setValidatedLogin(null, null, null, null, null, null);
    sessionStorage.clear();
    this.router.navigate(["/login"]);
    this.SpinnerService.hide();
  }

  async backSelector() {
    this.SpinnerService.show();
    await this.modificacionesTurnos.detenerServicio();
    this.router.navigate(["/selector"]);
    if (this.sessionEditPermission) {
      this.endAdminSession().then((resp) => {});
    }
    this.timer.cleanUp();
    this.SpinnerService.hide();
  }

  replacePropuestaInPlan(pedidosPropuesta) {
    pedidosPropuesta.forEach((pp) => {
      var pedidoToReplace = this.pedidos.filter(
        (e) => e["VBELN"] == pp["VBELN"]
      );
      for (var [key, value] of Object.entries(pp)) {
        if (key != "WRITE_TIMESTAMP") {
          pedidoToReplace[0][key] = value;
        }
      }
    });
  }

  nuevaPropuesta() {
    if (!this.sessionEditPermission) {
      return;
    }
    this.fetchDataIsReady = false;
    this["responseLocationResultPropuesta"] = "";
    this.SpinnerService.show();
    this.noProgrammedOrderElements = [];

    var body: NewProposalDataRequest = {
      fecha_a_programar: this.utils.swapDate(this.plantData.date),
      planta: this.plantData.code,
      pedidos: this.pedidos,
      sap_user: this.loginData.user_name,
      sap_pwd: this.loginData.user_pass,
      token: this.loginData.bearer,
    };

    var request = {
      function: "nueva_propuesta",
      request: body,
    };

    let response = this.api.sendSqs(request);

    response.subscribe(
      (data: { msg_queue_id: string }) => {
        var msg_queue_id = data.msg_queue_id;
        this.sqsResultPropuestaTries = 0;
        this.getSqsResultPropuesta(msg_queue_id);
      },
      (err) => {
        this.SpinnerService.hide();
        this.openAlert("danger", err);
      }
    );
  }

  emptyProg() {
    this.trucks = [];
    this.pedidos = [];
    this.minuteCells = [];
    // los pedidos programados html
    this.refresh();
  }

  update() {
    this.timer.updateExpiredTime();
    this.tpoCarga = 0;
    this.tpoDesca = 0;
    if (
      !this.sessionEditPermission &&
      this.loginData.user_name != this.sessionAdmin
    ) {
      this.manageSession();
    }
    this.programadorFunctions.delDistrListReg();
    this.emptyProg();
    this.getData();
    this.generateGridData();
    this.sqsIterations.msg = "start";
    this.sqsIterations.count = 0;
    this.sqsIterationsTrucks.msg = "start";
    this.sqsIterationsTrucks.count = 0;
    this.getDefaultValues(
      this.plantData.code,
      this.destinoDefault,
      this.plantData.code
    );
  }

  // vacia la grilla para despues de un refresh, cargar los datos
  emptyGrid() {
    var notAvail = this.minuteCells.filter((e) => e.componentRef != undefined);
    notAvail.forEach((e) => {
      e.removePlannedOrder();
    });
  }

  highlightTruck(value) {
    var typedWord = value.toString().trim().toLowerCase();

    this.SpinnerService.show();
    // NAME1_KUNAG VBELN
    this.pedidos.forEach((p) => {
      p["HIGHLIGHTED"] = "N";

      if (
        (typedWord != "" &&
          p.NAME1_KUNAG.trim().toLowerCase().indexOf(typedWord) !== -1) ||
        (typedWord != "" && p.VBELN.trim().indexOf(typedWord) !== -1) ||
        (typedWord != "" && p.VEHICLE.trim().indexOf(typedWord) !== -1) ||
        (typedWord != "" && p.KUNAG.trim().indexOf(typedWord) !== -1)
      ) {
        p["HIGHLIGHTED"] = "S";
        this.trucks.forEach((t, idx) => {
          if (p.VEHICLE == t.truckId && p["HIGHLIGHTED"] == "S") {
            // if(idx==0){
            // location.hash = p.VEHICLE
            var firstCoincidence = document.getElementById(p.VEHICLE);
            firstCoincidence.scrollIntoView();
            // }
            t["HIGHLIGHTED"] = "S";
          }
        });
      } else {
        this.trucks.forEach((t) => {
          if (p.VEHICLE == t.truckId) {
            t["HIGHLIGHTED"] = "N";
          }
        });
      }
    });

    this.SpinnerService.hide();
  }

  showSapMessages() {
    if (this.ERc == undefined) {
      // alert("No estamos definiendo ERc")
    }
    if (this.EMessage == undefined) {
      // alert("No estamos definiendo EMessage")
    }
    if (this.ERc != 0) {
      this.openAlert("danger", this.EMessage);
    }
  }

  // Obtener los tiempos de carga por planta para utilizarlo en propiedades tpoCarga y tpoDescarga, valores que se asignan dentro del flujo
  // cuando hay pedidos no combinados
  async getDefaultValues(origen, destino, codigo_planta) {
    const values = await this.api.getTimesForSchedule(
      origen,
      destino,
      codigo_planta
    );
    this.tpoCarga = values["body"].tiempo_carga_default;
    this.tpoDesca = values["body"].tiempo_descarga_default;
  }

  //método para abrir modal estado propuesta
  showEstadoPropuesta() {
    const modalRef = this.modalService.open(ModalStatusPropuestaComponent, {
      size: "lg",
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.componentInstance.modalInfo.name = this.plantData.name;
    modalRef.componentInstance.modalInfo.date = this.plantData.date;
    modalRef.componentInstance.modalInfo.code = this.plantData.code;
    modalRef.result.then((resp) => resp).catch((err) => err);
  }

  checkProposalDate() {
    let date: Date = new Date();
    let copiedDate: Date = new Date(date);
    let copiedDate2: Date = new Date(date);

    const modifyDate = (objDate: Date, sysDate: Date, days: number) => {
      return objDate.setDate(sysDate.getDate() + days);
    };

    let alteredPlantDate1Day = modifyDate(copiedDate, date, 1);
    let alteredPlantDate2Day = modifyDate(copiedDate2, date, 2);

    if (this.utils.parsearFechaALocalCL(date) === this.plantData.date) {
      this.showProposalButton = false;
    } else if (
      this.plantData.date ===
        this.utils.parsearFechaALocalCL(alteredPlantDate1Day) ||
      this.plantData.date ===
        this.utils.parsearFechaALocalCL(alteredPlantDate2Day)
    ) {
      this.showProposalButton = true;
    } else {
      this.showProposalButton = false;
    }
  }

  async endAdminSession() {
    return await this.api
      .sessionState({
        user: this.loginData.user_name,
        operation: "delete",
        payload: {
          session_date: this.plantData.date,
          plant: this.plantData.code,
        },
      })
      .toPromise();
  }

  async startAdminSession() {
    return await this.api
      .sessionState({
        user: this.loginData.user_name,
        operation: "create",
        payload: {
          session_date: this.plantData.date,
          plant: this.plantData.code,
        },
      })
      .toPromise();
  }

  manageSession() {
    this.startAdminSession()
      .then((resp) => {
        this.sessionAdmin = resp["data"].sap_user;

        if (this.sessionAdmin === this.loginData.user_name) {
          this.sessionEditPermission = true;
          this.openAlert("success", resp["message"]);
        } else {
          this.sessionEditPermission = false;
        }
      })
      .catch((e) => {});
  }

  backButtonClick() {
    this.backButtonOBS = this.router.events.subscribe(
      (event: NavigationStart) => {
        if (
          event.navigationTrigger === "popstate" &&
          event.url.includes("selector") &&
          this.sessionEditPermission
        ) {
          this.endAdminSession().then((resp) => {});
          this.timer.cleanUp();
        }
      }
    );
  }

  getPedidosDeleteStruct() {
    if (
      !this.pedidosLoaded ||
      !this.trucksLoaded ||
      !this.modificationsStatus
    ) {
      this.getPedidosDeleteStruct();
      return;
    }

    this.dataDesconfirmar = [];
    const dataPedidosDesconfirmar = [];
    const pedidosModificadosDesconfirmar = [];
    const pedidosModificadosBajar = [];
    const pedidosModificados = [];

    for (const actualizacion of this.dataActualizaciones) {
      const vehiculoActualizado = actualizacion.id_vehiculo
        .toString()
        .padStart(10, "0");
      const pedidosProgramadosEnVehiculo = this.pedidos.filter(
        (data) => data.VEHICLE === vehiculoActualizado
      );

      for (const pedidoProgramadoEnVehiculo of pedidosProgramadosEnVehiculo) {
        const fechaInicioAnterior: string[] =
          actualizacion.fecha_inicio_anterior.split(/[T,]/);
        const fechaFinAnterior: string[] =
          actualizacion.fecha_fin_anterior.split(/[T,]/);
        const dateInicioAnterior: Date =
          this.utils.getStringtoDate(fechaInicioAnterior);
        const dateFinAnterior: Date =
          this.utils.getStringtoDate(fechaFinAnterior);
        const PRO_BEG_DATETIME: Date = this.utils.getSubStringDate(
          pedidoProgramadoEnVehiculo.PRO_BEGDA,
          pedidoProgramadoEnVehiculo.PRO_BEGTI
        );

        const programaIniciaDentroDelTurnoAnterior: boolean =
          PRO_BEG_DATETIME >= dateInicioAnterior &&
          PRO_BEG_DATETIME <= dateFinAnterior;

        if (
          programaIniciaDentroDelTurnoAnterior &&
          actualizacion.accion === "ELIMINADO"
        ) {
          this.pedidos.map((data) => {
            if (
              data.VEHICLE === vehiculoActualizado &&
              data.VBELN === pedidoProgramadoEnVehiculo.VBELN
            ) {
              data.VEHICLE = " ";

              if (data.ESTADO === "3") {
                const infoDesconfirmar = {
                  VBELN: data.VBELN,
                  VBTYP: data.VBTYP,
                  MOTIVO: "",
                  SUBRC: "",
                  MESSAGE: "",
                  TIPO: "P",
                  OBSERVACION: data.COMENTARIOS,
                  OBJETO_PEDIDO: data,
                };

                dataPedidosDesconfirmar.push(infoDesconfirmar);
                pedidosModificadosDesconfirmar.push(data.VBELN);
              } else {
                pedidosModificadosBajar.push(data.VBELN);
              }
            }
          });
        } else {
          const fechaInicio: string[] =
            actualizacion.fecha_inicio.split(/[T,]/);
          const fechaFin: string[] = actualizacion.fecha_fin.split(/[T,]/);
          const dateInicio: Date = this.utils.getStringtoDate(fechaInicio);
          const dateFin: Date = this.utils.getStringtoDate(fechaFin);

          const programaIniciaFueraDelNuevoTurno: boolean =
            PRO_BEG_DATETIME < dateInicio || PRO_BEG_DATETIME >= dateFin;

          if (
            programaIniciaDentroDelTurnoAnterior &&
            programaIniciaFueraDelNuevoTurno
          ) {
            this.pedidos.map((data) => {
              if (
                data.VEHICLE === vehiculoActualizado &&
                data.VBELN === pedidoProgramadoEnVehiculo.VBELN
              ) {
                data.VEHICLE = " ";

                if (data.ESTADO === "3") {
                  const infoDesconfirmar = {
                    VBELN: data.VBELN,
                    VBTYP: data.VBTYP,
                    MOTIVO: "",
                    SUBRC: "",
                    MESSAGE: "",
                    TIPO: "P",
                    OBSERVACION: data.COMENTARIOS,
                    OBJETO_PEDIDO: data,
                  };

                  dataPedidosDesconfirmar.push(infoDesconfirmar);
                  pedidosModificadosDesconfirmar.push(data.VBELN);
                } else {
                  pedidosModificadosBajar.push(data.VBELN);
                }
              }
            });
          }
        }
      }
    }

    if (pedidosModificadosDesconfirmar.length > 0) {
      this.dataDesconfirmar = dataPedidosDesconfirmar;
      const pedidoModificado = {
        accion: "Desconfirmar",
        pedidos: pedidosModificadosDesconfirmar,
      };
      pedidosModificados.push(pedidoModificado);
    }

    if (pedidosModificadosBajar.length > 0) {
      const pedidoModificado = {
        accion: "Bajar",
        pedidos: pedidosModificadosBajar,
      };
      pedidosModificados.push(pedidoModificado);
    }

    this.abrirModalModificationsModal(pedidosModificados);
  }

  async abrirModalModificationsModal(pedidosModificados) {
    if (this.componentRefGetsModificationsModal === undefined) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ModalGetsModificationsComponent
        );
      this.componentRefGetsModificationsModal =
        this.containerGetsModifications.createComponent(componentFactory);
    }
    this.componentRefGetsModificationsModal.instance.setDetailsModificacions(
      this.dataActualizaciones,
      pedidosModificados
    );
    this.componentRefGetsModificationsModal.instance.show();
    this.updateModificaciones = false;
  }
}
